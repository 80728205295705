import React from 'react';
import Table from '../../../Common/Table/Table';
import formatDate from 'dateformat';
import Button from '../../../Common/Button/Button';
import { downloadFile } from '../../../../utils/download';
import { TableProps } from '../../../Common/Table/types';
import { Avoir } from '../MesAvoirs';

type Props = {
	avoirs: Avoir[];
	loading: boolean;
	error: string;
	selectableRows: NonNullable<TableProps['selectableRows']>;
};

const ListeAvoirs = ({ avoirs, loading, error, selectableRows }: Props) => {
	return (
		<Table
			columns={[
				{ content: `Date d'émission'` },
				{ content: `Numéro d'avoir`, mobileHidden: true },
				{ content: '', center: true, isButton: true },
			]}
			rows={avoirs.map((avoir: Avoir) => {
				const dotIndex = avoir.pdfName.lastIndexOf('.');
				const pdfName =
					avoir.pdfName.substring(0, dotIndex) +
					`-${avoir.numeroAvoir}` +
					avoir.pdfName.substring(dotIndex);

				return {
					id: avoir.id,
					mobileTitle: `Avoir ${avoir.numeroAvoir}`,
					cells: [
						{
							content: formatDate(avoir.dateEmission, 'dd/mm/yyyy'),
						},
						{
							content: avoir.numeroAvoir,
						},
						{
							content: (
								<Button
									outline
									title={`Télécharger l'avoir ${pdfName}`}
									onClick={() => {
										downloadFile({
											href: avoir.pdfPath,
											name: pdfName,
										});
									}}
								>
									Télécharger
								</Button>
							),
						},
					],
				};
			})}
			selectableRows={selectableRows}
			noResultMessage={`Pas d'avoir`}
			error={error}
			loading={loading}
		/>
	);
};

export default ListeAvoirs;
