import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import styles from './FormulaireProduit.module.scss';
import { DataProduitProps } from '../../../types/Produit';

import { SubmitHandler, useForm } from 'react-hook-form';
import Input from '../Input/Input';
import Checkbox from '../../Common/Checkbox/Checkbox';
import NewCheckBox from '../Checkbox/Checkbox';
import Button from '../../Common/Button/Button';
import Dropzone from '../../Common/Dropzone/Dropzone';
import ErrorMessage from '../../Common/Form/ErrorMessage/ErrorMessage';
import { AppState } from '../../../store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const createMutation = loader('./mutations/createProduit.graphql');
const updateMutation = loader('./mutations/updateProduit.graphql');
const createTarif = loader('./mutations/createTarif.graphql');
const updateTarif = loader('./mutations/updateTarif.graphql');

const getOriginesQuery = loader('./queries/getOrigines.graphql');
const getCategoriesQuery = loader('./queries/getCategories.graphql');
const getSousCategoriesQuery = loader('./queries/getSousCategories.graphql');
const getLabelsQuery = loader('./queries/getLabels.graphql');

interface FormValues {
	nom: string;
	code: string;
	categorie: string;
	sousCategorie: string;
	image: FileList;
	labels: string[];
	tarification: String;
	origine: string;
	unite: 'KG' | 'COLIS' | 'PIECE' | 'LOT';
	auCour: boolean;
	disabled: boolean;
}

interface Label {
	id: string;
	nom: string;
}

interface FormulaireProduitProps extends RouteComponentProps {
	data?: DataProduitProps;
	type?: 'create' | 'update';
	fournisseurId: string;
}

function FormulaireProduit({
	data,
	type,
	fournisseurId,
}: FormulaireProduitProps) {
	const { isMounted } = useUnmount();
	const [done, setDone] = useState(false);

	const [loading, setLoading] = useState(false);

	const [categorie, changeCategorie]: any = useState('');
	const [sousCategorie, changeSousCategorie]: any = useState('');

	const [newOrigines, setNewOrigines]: any = useState();
	const [newCategories, setNewCategories]: any = useState();
	const [newSousCategories, setNewSousCategories]: any = useState();
	const [newLabels, setNewLabels]: any = useState();

	const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
	const [origine, changeOrigine]: any = useState('');

	const [error, setError] = useState('');

	const [imageSrc, changeImageSrc] = useState('');
	const [imageFile, setImageFile] = useState<File | undefined>(undefined);

	const unites = [{ value: 'KG' }, { value: 'COLIS' }, { value: 'PIECE' }];

	const [productId, setProductId]: any = useState('');

	useEffect(() => {
		/* eslint-disable @typescript-eslint/no-unused-vars */
		let isMounted = true;
		type OrigineResponse = { origines: [] };

		request<OrigineResponse>(getOriginesQuery)
			.then((res) => {
				if (res) {
					setNewOrigines(res.origines);
				}
			})
			.catch((err) => {});

		type CategoriesResponse = { categories: [] };
		request<CategoriesResponse>(getCategoriesQuery, {
			where: { fournisseur: { id: fournisseurId } },
		})
			.then((res) => {
				if (res) {
					setNewCategories(res.categories);
				}
			})
			.catch((err) => {});

		type LabelsResponse = { labels: [] };
		request<LabelsResponse>(getLabelsQuery)
			.then((res) => {
				if (res) {
					setNewLabels(res.labels);
				}
			})
			.catch((err) => {});

		return () => {
			isMounted = false;
		};
	}, [isMounted, fournisseurId]);

	async function onChangeCategorie(value: any) {
		type QueryResponse = { sousCategories: [] };
		await request<QueryResponse>(getSousCategoriesQuery, {
			where: { categorie: { id: value } },
		})
			.then((res) => {
				if (res) {
					setNewSousCategories(res.sousCategories);
					changeCategorie(value);
				}
			})
			.catch((err) => {});
	}

	const handleCheckboxChange = (id: string, isChecked: boolean) => {
		if (isChecked) {
			// Ajouter l'ID à la liste des éléments sélectionnés
			setSelectedLabels((prevSelectedLabels) => [...prevSelectedLabels, id]);
			console.log(selectedLabels);
		} else {
			// Supprimer l'ID de la liste des éléments sélectionnés
			setSelectedLabels((prevSelectedLabels) =>
				prevSelectedLabels.filter((selectedId) => selectedId !== id),
			);
		}
	};
	const { register, errors, handleSubmit, reset } = useForm<FormValues>();

	useEffect(() => {
		if (data) {
			const origines = data?.origines;
			const firstOrigine = Array.isArray(origines) ? origines?.[0] : '';
			changeOrigine(firstOrigine);
			const sousCategorie = data?.sousCategorie;
			changeSousCategorie(sousCategorie);
			const categorie = sousCategorie?.categorie;
			changeCategorie(categorie);
			if (categorie) {
				type QueryResponse = { sousCategories: [] };
				request<QueryResponse>(getSousCategoriesQuery, {
					where: { categorie: { id: categorie.id } },
				})
					.then((res) => {
						if (res) {
							setNewSousCategories(res.sousCategories);
						}
					})
					.catch((err) => {});
			}
			const tarification = data?.tarifs;
			const prix = Array.isArray(tarification) ? tarification?.[0].prix : '';
			const selectedLabelsIdArray = data?.labels
				? data.labels.map((label) => label.id)
				: [];
			setSelectedLabels(selectedLabelsIdArray);
			changeImageSrc(data?.imageSrc);

			setProductId(data?.id);
			reset({
				nom: data?.nom,
				code: data?.code,
				unite: data?.unite,
				tarification: prix,
			});
		}
	}, [data, reset]);

	const onSubmitCreate: SubmitHandler<FormValues> = async (data) => {
		const {
			categorie,
			nom,
			code,
			sousCategorie,
			image,
			labels,
			tarification,
			unite,
			auCour,
			disabled,
		} = data;
		const labelsToArray = labels.map((label) => ({
			id: label,
		}));

		try {
			setError('');
			setLoading(true);
			const { createProduit } = await request(createMutation, {
				categorie,
				sousCategorie,
				nom,
				code,
				origine,
				image: image[0],
				labels: labelsToArray,
				unite,
				auCour,
				disabled,
			});
			await request(createTarif, {
				prix: Number(tarification),
				produit: createProduit.id,
				fournisseur: createProduit.fournisseur.id,
			});
			if (isMounted.current) setDone(true);
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	};

	const onSubmitUpdate: SubmitHandler<FormValues> = async (data) => {
		const {
			categorie,
			nom,
			code,
			sousCategorie,
			labels,
			tarification,
			unite,
			auCour,
			disabled,
			origine,
		} = data;
		const labelsToArray = labels.map((label) => ({
			id: label,
		}));

		try {
			setError('');
			setLoading(true);
			const { updateProduit } = await request(updateMutation, {
				where: { id: productId },
				nom,
				code,
				unite,
				categorie,
				sousCategorie,
				origine,
				image: imageFile,
				labels: labelsToArray,
				auCour,
				disabled,
			});
			await request(updateTarif, {
				prix: Number(tarification),
				where: { id: updateProduit.tarifs[0].id },
			});
			if (isMounted.current) setDone(true);
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	};

	return done ? (
		<Redirect to="/produits/liste" />
	) : (
		<form
			onSubmit={handleSubmit(
				type === 'create' ? onSubmitCreate : onSubmitUpdate,
			)}
			className={styles.form}
		>
			<div className={styles.container}>
				<div className={styles.table1}>
					<h1 className={styles.tableTitle}>Description produit</h1>
					<div>
						<h1 className={styles.hh}>
							Categorie : <span className={styles.span}>*</span>
						</h1>
						<select
							className={styles.select}
							onChange={(l) => onChangeCategorie(l.target.value)}
							name="categorie"
							ref={register({ required: true })}
							value={categorie ? categorie.id : ''}
						>
							<option value="">- Choisir une categorie</option>

							{newCategories === undefined ? (
								<option></option>
							) : (
								newCategories.map((e: any) => (
									<option value={e.id} selected={categorie === e.id} key={e.id}>
										{e.nom}
									</option>
								))
							)}
						</select>

						{errors.categorie && (
							<ErrorMessage>{'Veuillez indiquer une categorie'}</ErrorMessage>
						)}
					</div>

					<div>
						<h1 className={styles.hh}>
							Sous-categorie : <span className={styles.span}>*</span>
						</h1>
						{categorie !== '' ? (
							<select
								className={styles.select}
								onChange={(l) => changeSousCategorie(l.target.value)}
								name="sousCategorie"
								ref={register({ required: true })}
								value={sousCategorie ? sousCategorie.id : ''}
							>
								<option value="">- Choisir une sous-categorie</option>

								{newSousCategories === undefined ? (
									<option></option>
								) : (
									newSousCategories.map((e: any) => (
										<option value={e.id} selected={sousCategorie === e.id}>
											{e.nom}
										</option>
									))
								)}
							</select>
						) : (
							<h1 className={styles.h1}>Veuillez choisir une categorie</h1>
						)}
						{errors.categorie && (
							<ErrorMessage>
								{'Veuillez indiquer une sous-categorie'}
							</ErrorMessage>
						)}
					</div>
					<div>
						<Input
							label="Nom du Produit: *"
							name="nom"
							type="text"
							className=""
							register={register({ required: true })}
							readOnly={false}
							// readOnly={url === 'creation' ? false : true}
						/>
						{errors.nom && (
							<ErrorMessage>
								{'Veuillez indiquer un nom de produit'}
							</ErrorMessage>
						)}
					</div>
					<div>
						<Input
							label="Code Produit: *"
							name="code"
							type="text"
							className=""
							register={register({ required: true })}
							// readOnly={url === 'creation' ? false : true}
						/>
						{errors.code && (
							<ErrorMessage>{'Veuillez indiquer un code produit'}</ErrorMessage>
						)}
					</div>
					<div className={styles.labels}>
						<h1 className={styles.hh}>
							Labels: <span className={styles.span}>*</span>
						</h1>
						<div className={styles.labelsContainer}>
							{newLabels === undefined ? (
								<></>
							) : (
								newLabels.map((label: Label) => {
									return (
										<label className={styles.label} key={label.id}>
											<h1>
												{label.nom}
												{/* <span className={styles.span}>*</span> */}
											</h1>
											<Checkbox
												name="labels"
												register={register({ required: true })}
												loading={loading}
												checked={selectedLabels.includes(label.id)}
												onClick={(checked) => {
													handleCheckboxChange(label.id, checked);
												}} // Passer l'ID et l'état
												value={label.id}
											/>
										</label>
									);
								})
							)}
						</div>
						{errors.labels && (
							<ErrorMessage>
								{'Veuillez indiquer au moins un label'}
							</ErrorMessage>
						)}
					</div>
					<div className={styles.labels}>
						<h1 className={styles.hh}>Au cour:</h1>
						<div className={styles.labelsContainer}>
							<label className={styles.label}>
								<h1>Au cour</h1>
								<NewCheckBox
									name="auCour"
									register={register}
									isAllReadyChecked={data?.aucour}
								/>
							</label>
						</div>
					</div>
					<div className={styles.labels}>
						<h1 className={styles.hh}>Disabled:</h1>
						<div className={styles.labelsContainer}>
							<label className={styles.label}>
								<h1>
									Disabled
									{/* <span className={styles.span}>*</span> */}
								</h1>
								<NewCheckBox
									name="disabled"
									register={register}
									isAllReadyChecked={data?.disabled}
								/>
							</label>
						</div>
					</div>
					<div>
						<h1 className={styles.hh}>
							Origine : <span className={styles.span}>*</span>
						</h1>
						<select
							className={styles.select}
							onChange={(l) => changeOrigine(l.target.value)}
							name="origine"
							ref={register({ required: true })}
							value={origine ? origine.id : ''}
						>
							<option value="">- Choisir un pays</option>
							{newOrigines === undefined ? (
								<option></option>
							) : (
								newOrigines.map((e: any) => (
									<option value={e.id} selected={origine === e.id} key={e.id}>
										{e.nom}
									</option>
								))
							)}
						</select>
						{errors.origine && (
							<ErrorMessage>{'Veuillez indiquer une origine'}</ErrorMessage>
						)}
					</div>

					<div className={styles.tarification}>
						<h1 className={styles.tableTitle}>Tarification produit</h1>
						<div>
							<Input
								label="Prix de base: *"
								name="tarification"
								type="text"
								className=""
								register={register({ required: true })}
								readOnly={false}
							/>
							{errors.tarification && (
								<ErrorMessage>
									{'Veuillez indiquer une tarification'}
								</ErrorMessage>
							)}
						</div>
						<div>
							<h1 className={styles.hh}>
								Unite : <span className={styles.span}>*</span>
							</h1>
							<select
								className={styles.select}
								name="unite"
								ref={register({ required: true })}
							>
								<option value="">- Choisir une unité</option>

								{unites.map((u: any) => (
									<option value={u.value} key={u.value}>
										{u.value}
									</option>
								))}
							</select>
							{errors.unite && (
								<ErrorMessage>{'Veuillez indiquer une unite'}</ErrorMessage>
							)}
						</div>
						<div className={styles.aVenir}>
							<Input
								label="Prix sur une période"
								name="tarificationPeriode"
								type="text"
								className=""
								readOnly={true}
								placeholder="A venir..."
								register={register}
							/>
						</div>
						<div className={styles.aVenir}>
							<Input
								label="Prix Promo sur une période"
								name="tarificationPromoPeriode"
								type="text"
								className=""
								readOnly={true}
								placeholder="A venir..."
								register={register}
							/>
						</div>
						<div
							className={`${styles.tarificationAjouterButtonContainer} ${styles.aVenir}`}
						>
							<Button disabled={true}>Ajouter un prix ( période )</Button>
							<Button disabled={true}>Ajouter une promotion</Button>
						</div>
					</div>
					<div className={styles.table2}>
						<div className={styles.photo}>
							<h1 className={styles.h1}>Photo</h1>
							<Dropzone
								imageSrc={imageSrc}
								imageWidthToDisplay={600}
								onChange={({ tempURL, file }) => {
									setImageFile(file);
									changeImageSrc(tempURL);
								}}
								register={register}
								name="image"
							/>
						</div>
					</div>
					<ErrorMessage>{error}</ErrorMessage>
					<Button submit className={styles.btnSubmit}>
						{type === 'create'
							? 'Ajouter un produit'
							: 'Enregistrer les modifications'}
					</Button>
				</div>
			</div>
		</form>
	);
}

function mapStateToProps(state: AppState) {
	return { fournisseurId: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(FormulaireProduit));
