import { loader } from 'graphql.macro';
import { User } from '../../../store/compte/types';
import { PanierState } from '../../../store/panier/types';
import request from '../../../utils/request';
import { QueryResponse } from './ResultatsRecherche';

const queryProduits = loader('./queryProduits.graphql');
const queryClient = loader('./getClient.graphql');

interface Props {
	nbrF: number;
	idClient: User['id'];
	dateLivraison?: PanierState['dateLivraison'];
	pageIndex: number;
	nbrTotalF: number;
	itemsPerPage: number;
	activeQuery: string;
	activeLabel: string | null;
	activeOrigine: string | null;
	activeMarque: string | null;
	activeSelection: string | null;
	activeSousCategorie: string | null;
}

type Client = {
	fournisseur: {
		id: string;
	};
};

export async function searchInProduits({
	nbrF,
	idClient,
	dateLivraison,
	nbrTotalF,
	pageIndex,
	itemsPerPage,
	activeLabel,
	activeQuery,
	activeOrigine,
	activeMarque,
	activeSelection,
	activeSousCategorie,
}: Props) {
	// Tous ces calculs sont incompréhensibles comme ça,
	// mais ils servent à calculer le first et le skip pour les produits.
	// const first = itemsPerPage - nbrF;
	const nbrPagesFPleines = Math.floor(nbrTotalF / itemsPerPage);
	const resteF = nbrTotalF % itemsPerPage;
	const nbrPagesP = pageIndex - nbrPagesFPleines;
	const skip = (nbrPagesP - 1) * itemsPerPage - resteF;
	const client = await getClient(idClient);
	const idFournisseur = client.fournisseur.id;

	const params = {
		idFournisseur,
		dateLivraison,
		search: activeQuery
			? activeQuery
					.toLowerCase()
					.split(' ')
					.join('')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			: null,
		first: 15,
		skip: skip,
		where: {
			AND: [{ disabled: false }, { fournisseur: { id: idFournisseur } }],
			// tarifs_some: {
			// 	fournisseur: { clientsAutorises_some: { id: idClient } },
			// },
			...(activeSelection && {
				selections_some: { slug: activeSelection },
			}),
			...(activeSousCategorie && {
				sousCategorie: { slug: activeSousCategorie },
			}),
			...(activeLabel && { labels_some: { slug: activeLabel } }),
			...(activeOrigine && { origines_some: { slug: activeOrigine } }),
			...(activeMarque && { mark_some: { slug: activeMarque } }),
			...(activeQuery && {
				AND: activeQuery.split(' ').map((s) => {
					return {
						OR: [
							{
								search_contains: s
									.toLowerCase()
									.normalize('NFD')
									.replace(/[\u0300-\u036f]/g, ''),
							},
						],
					};
				}),
			}),
		},
	};

	type T = Pick<QueryResponse, 'countProduits' | 'produits'>;
	return request<T>(queryProduits, params);
}

const getClient = async (id: string) => {
	const variables = { id };
	const response = await request<{ clients: Client[] }>(queryClient, variables);
	return response.clients[0];
};
