import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
	Link,
	withRouter,
	RouteComponentProps,
	Redirect,
} from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import request from '../../../utils/request';
import Button from '../../Common/Button/Button';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './Categorie.module.scss';
import { Fournisseur, Categorie } from '../../../types';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import OrderBy from '../../Common/OrderBy/OrderBy';
import TablePagination from '../../Common/Table/TablePagination/TablePagination';
import CategorieListe from './CategorieListe/CategorieListe';
const queryCategories = loader('./getCategories.graphql');

export interface QueryResponse {
	countCategories: number;
	categories: Categorie[];
}
export interface Props extends RouteComponentProps {
	idFournisseur: Fournisseur['id'];
}
const itemsPerPage = 10;

function _Categories({ idFournisseur, location: { search, pathname } }: Props) {
	useTitle('Mes categories');

	const [categories, setCategorie] = useState<QueryResponse['categories']>([]);

	const [countTotal, setCountTotal] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const searchParams = new URLSearchParams(search);
	const pageIndex = +(searchParams.get('page') || 1);
	const orderBy = searchParams.get('orderBy') || '';
	const order = searchParams.get('order') || '';

	useEffect(() => {
		if (!orderBy || !order) return;
		let isMounted = true;
		setLoading(true);
		setError('');

		const params = {
			where: {
				fournisseur: { id: idFournisseur },
			},
			first: itemsPerPage,
			orderBy: `${orderBy}_${order}`,
			skip: itemsPerPage * (pageIndex - 1),
		};
		request<QueryResponse>(queryCategories, params)
			.then((res) => {
				isMounted && setCategorie(res.categories);
				isMounted && setCountTotal(res.countCategories);
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idFournisseur, order, orderBy, pageIndex]);

	if (!orderBy || !order) {
		return <Redirect to={`${pathname}?orderBy=updatedAt&order=DESC`} />;
	}

	// function deleteClient(id: string) {
	// 	setError('');

	// 	request(mutationDeleteProduit, { id })
	// 		.then(() => setProduits(produits.filter((a) => a.id !== id)))
	// 		.catch((err) => setError(err.message));
	// }

	async function updateCategorie(id: string) {
		setError('');
		window.location.href = `/categorie/edition/${id}`;
	}

	return (
		<TableWrapper>
			<div>
				<div className={styles.buttonWrapper}>
					<Link to={{ pathname: '/categorie/create' }}>
						<Button submit loading={loading}>
							Ajouter une categorie
						</Button>
					</Link>
				</div>
			</div>
			<TableHeader>
				<OrderBy fields={[{ name: 'Nom', slug: 'nom' }]} />
			</TableHeader>
			<CategorieListe
				error={error}
				loading={loading}
				noResultMessage={"Vous n'avez aucune catégorie."}
				categorie={categories}
				// deleteClient={deleteClient}
				updateCategorie={updateCategorie}
			/>
			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={countTotal}
				itemsCountPerPage={itemsPerPage}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { idFournisseur: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(_Categories));
