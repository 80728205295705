import formatDate from 'dateformat';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { convertStatus, truncate } from '../../../../utils/french';
import request from '../../../../utils/request';
import BtnFavori from '../../../Common/BtnFavori/BtnFavori';
import BtnPoubelle from '../../../Common/BtnPoubelle/BtnPoubelle';
import Table from '../../../Common/Table/Table';
import { RowProps } from '../../../Common/Table/types';
import InfoRecurrence from '../../Recurrence/InfoRecurrence/InfoRecurrence';
import { QueryResponse } from '../Commandes';
import styles from './ListeCommandes.module.scss';

// const setFavoriMutation = loader('./setFavori.graphql');
const deleteCmdFav = loader('./deleteCmdFav.graphql');

export interface Props extends RouteComponentProps {
	error?: string;
	loading: boolean;
	commandes: QueryResponse['commandes'];
	noResultMessage: string;
	updateCmd: () => void;
	favorites?: boolean | undefined;
	setNoCommandeFavorite: (id: string, isFavorite: boolean) => void;
	setCommandeFavorite: (id: string, isFavorite: boolean) => void;
}

export function _ListeCommandes({
	error,
	loading,
	commandes,
	noResultMessage,
	updateCmd,
	setCommandeFavorite,
	setNoCommandeFavorite,
	favorites,
	location: { pathname },
}: Props) {
	const [toogle, setToogle] = useState(false);
	const [rdirect, setRedirect] = useState(false);

	async function toggleCommandeFavorite(id: string, isFavorite: boolean) {
		setCommandeFavorite(id, isFavorite);
		await setToogle(!toogle);
		// request(setFavoriMutation, { id, favorite: !isFavorite });

		setTimeout(() => {
			setRedirect(true);
		}, 500);
	}

	async function deleteCammandeFavorite(id: string) {
		await request(deleteCmdFav, { id });
		updateCmd();
	}

	// async function deleteCmd(id: any, index: any) {
	// 	await request(deleteCmdFav, { id });
	// 	updateCmd();
	// }

	useEffect(() => {});

	return rdirect ? (
		<Redirect to="/commandes-favorites?orderBy=numero&order=DESC" />
	) : (
		<Table
			error={error}
			loading={loading}
			noResultMessage={noResultMessage}
			columns={[
				{ content: 'Date de passage de commande' },
				{ content: 'Numéro', mobileHidden: true },
				{ content: 'Nom' },
				{ content: 'Récurrence', center: true },
				{ content: 'Produits' },
				{ content: 'État' },
				{ content: 'Favori', center: true, isButton: true },
			]}
			rows={commandes.map((c, index) => {
				const mois = c.recurrenceMois;
				const semaine = c.recurrenceSemaine;
				const url = favorites
					? `/commandes-favorites/${c.id}?favorite=${favorites}&?from=${pathname}`
					: `/commande/${c.id}?from=${pathname}&favorite=${favorites}`;
				return {
					id: c.id,
					href: url,
					mobileTitle: <p>Commande n°{c.numero}</p>,
					cells: [
						{ content: formatDate(c.createdAt, 'dd/mm/yyyy') },
						{ content: `N°${c.numero}`, className: styles.numero },
						{ content: c.nom, className: styles.nom },
						{
							content: mois ? (
								<InfoRecurrence mois={mois.date} />
							) : semaine ? (
								<InfoRecurrence semaine={semaine.map((s) => s.jour)} />
							) : null,
						},
						{
							content: truncate(
								c.lignes.map((l) => l.produit.nom).join(', '),
								60,
							),
							className: styles.produits,
						},
						{ content: convertStatus(c.lignes[0].statut) },
						{
							stopPropagation: true,
							content: favorites ? (
								<>
									<BtnPoubelle
										title="Enlever le produit du panier"
										onClick={() => deleteCammandeFavorite(c.id)}
									/>
								</>
							) : (
								<BtnFavori
									checked={false}
									onClick={() => toggleCommandeFavorite(c.id, c.favorite)}
									title={
										c.favorite
											? 'Enlever cette commande des favoris'
											: 'Ajouter cette commande aux favoris'
									}
								/>
							),
						},
					] as RowProps['cells'],
				} as RowProps;
			})}
		/>
	);
}

export default withRouter(_ListeCommandes);
