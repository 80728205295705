import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Pick2 } from 'ts-multipick';
import { useTitle } from '../../../hooks/useTitle';
import { AppState } from '../../../store';
import {
	Client,
	Commande,
	CommandeLigne,
	Contact,
	Fournisseur,
} from '../../../types';
import request from '../../../utils/request';
import FilterByDateRange from '../../Common/FilterByDateRange/FilterByDateRange';
import OrderBy from '../../Common/OrderBy/OrderBy';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import TablePagination from '../../Common/Table/TablePagination/TablePagination';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import ListeCommandes from './ListeCommandes/ListeCommandes';
import { Facture } from '../../../types/Facture';
import { Avoir } from '../../../types/Avoir';

const query = loader('./getCommandes.graphql');
const itemsPerPage = 10;

export interface QueryResponse {
	countCommandes: number;
	commandes: (Pick<Commande, 'id' | 'dateLivraison' | 'numero' | 'statut'> & {
		client: Pick<Client, 'enseigne' | 'raisonSociale' | 'email'> & {
			contacts: Pick<Contact, 'nom' | 'prenom' | 'telephones'>[];
		};
		lignes: (Pick<CommandeLigne, 'quantite' | 'statut'> &
			Pick2<CommandeLigne, 'produit', 'nom' | 'unite'>)[];
		facture: Pick<Facture, 'id'>;
		avoir: Pick<Avoir, 'id'>;
	})[];
}

export interface Props extends RouteComponentProps {
	idFournisseur: Fournisseur['id'];
}

export function _Commandes({
	idFournisseur,
	location: { search, pathname },
}: Props) {
	useTitle('Mes commandes');

	const [commandes, setCommandes] = useState<QueryResponse['commandes']>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const searchParams = new URLSearchParams(search);
	const pageIndex = +(searchParams.get('page') || 1);
	const orderBy = searchParams.get('orderBy') || '';
	const order = searchParams.get('order') || '';
	const dateStart = searchParams.get('dateStart');
	const dateEnd = searchParams.get('dateEnd');

	useEffect(() => {
		if (!orderBy || !order) return;

		let isMounted = true;
		setLoading(true);

		const params = {
			idFournisseur,
			where: {
				lignes_some: { fournisseur: { id: idFournisseur } },
				...(dateStart && { dateLivraison_gte: dateStart }),
				...(dateEnd && { dateLivraison_lte: dateEnd }),
			},
			first: itemsPerPage,
			orderBy: `${orderBy}_${order}`,
			skip: itemsPerPage * (pageIndex - 1),
		};

		request<QueryResponse>(query, params)
			.then(({ commandes, countCommandes }) => {
				if (!isMounted) return;
				setCommandes(commandes);
				setTotalCount(countCommandes);
				setError('');
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [idFournisseur, order, orderBy, pageIndex, dateEnd, dateStart]);

	if (!orderBy || !order) {
		return <Redirect to={`${pathname}?orderBy=dateLivraison&order=DESC`} />;
	}

	return (
		<TableWrapper>
			<TableHeader>
				<OrderBy
					fields={[{ name: 'Date de livraison', slug: 'dateLivraison' }]}
				/>
				<FilterByDateRange label="Date de livraison" />
			</TableHeader>

			<ListeCommandes
				error={error}
				loading={loading}
				commandes={commandes}
				noResultMessage="Vous n'avez aucune commande"
			/>

			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={totalCount}
				itemsCountPerPage={itemsPerPage}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { idFournisseur: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(_Commandes));
