import React, { useState } from 'react';
import styles from './CreationProduit.module.scss';
import Dropzone from '../../Common/Dropzone/Dropzone';
import { useForm } from 'react-hook-form';
import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import { useToasts } from 'react-toast-notifications';
import Button from '../../Common/Button/Button';

const createProduitsFromXlsx = loader(
	'./mutations/createProduitsFromXlsx.graphql',
);

function XlsxForm() {
	const [fileXlsx, setFileXlsx] = useState<File | null>(null);
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit } = useForm();
	const { addToast, removeAllToasts } = useToasts();

	const onSubmit = async () => {
		setLoading(true);
		if (!fileXlsx) return;

		try {
			const { createProduitsFromXlsx: res }: any = await request(
				createProduitsFromXlsx,
				{
					xlsx: fileXlsx,
				},
			);
			addToast('Import terminé !', { appearance: 'success' });
			addToast(
				'Information : \n - Total = ' +
					res.total +
					' \n - Ignored :  ' +
					res.total_ignored +
					' \n -  Created : ' +
					res.total_imported,
				{ appearance: 'success' },
			);
			setTimeout(() => {
				removeAllToasts();
			}, 10000);
			return res;
		} catch (err) {
			addToast("Une erreur c'est produite", { appearance: 'error' });
			setTimeout(() => {
				removeAllToasts();
			}, 3000);
		} finally {
			setLoading(false);
		}
	};

	const handleFileChange = (file: File) => {
		setFileXlsx(file);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
			<div className={styles.container}>
				<div className={styles.table1}>
					<h1 className={styles.tableTitle}>
						Ajouter un ou plusieurs produits
					</h1>
					<div className={styles.table2}>
						<div className={styles.photo}>
							<h1 className={styles.h1}>Fichier produits</h1>
							<Dropzone
								// imageSrc={imageSrc}
								imageWidthToDisplay={600}
								onChange={({ tempURL, file }) => {
									handleFileChange(file);
								}}
								register={register}
								name="xlsxFile"
							/>
						</div>
					</div>
					{/* <ErrorMessage>{error}</ErrorMessage> */}
					<Button submit className={styles.btnSubmit} loading={loading}>
						Ajouter les produits
					</Button>
				</div>
			</div>
		</form>
	);
}

export default XlsxForm;
