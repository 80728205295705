import React, { useEffect, useState } from 'react';
import { useTitle } from '../../../hooks/useTitle';
import styles from './ProduitsSelection.module.scss';
import { AppState } from '../../../store';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import request from '../../../utils/request';
import { loader } from 'graphql.macro';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import { Link } from 'react-router-dom';
import Button from '../../Common/Button/Button';
import { french } from '../../../utils/french';
import BtnPoubelle from '../../Common/BtnPoubelle/BtnPoubelle';
import { RowProps } from '../../Common/Table/types';
import Table from '../../Common/Table/Table';
import Iframe from '../../Common/Iframe/Iframe';

const getSelectionByFournisseurReq = loader(
	'./getSelectionsByFournisseur.graphql',
);
const deleteSelectionReq = loader('./deleteSelection.graphql');

type Props = {
	fournisseurId: string;
};

type Selection = {
	id: string;
	nom: string;
	pictoSrc: string;
};

type ResponseSelections = {
	selections: Selection[];
};

const MesSelections = ({ fournisseurId }: Props) => {
	useTitle('Mes sélections');
	const [selections, setSelections] = useState<Selection[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const fetchSelections = async (fournisseurId: string) => {
		const { selections } = await request<ResponseSelections>(
			getSelectionByFournisseurReq,
			{
				fournisseurId: fournisseurId,
			},
		);

		return selections;
	};

	const deleteSelection = (id: string) => {
		request(deleteSelectionReq, {
			id: id,
		}).then();

		const updatedSelections = selections.filter(
			(selection) => selection.id !== id,
		);

		setSelections(updatedSelections);
	};

	useEffect(() => {
		fetchSelections(fournisseurId)
			.then((selections) => {
				setSelections(selections);
				setLoading(false);
			})
			.catch((error) => {
				setError('Impossible de récupére les sélections.');
			});
	}, [fournisseurId]);

	return (
		<TableWrapper>
			<div>
				<div className={styles.buttonWrapper}>
					<Link to={{ pathname: '/selection/create' }}>
						<Button submit loading={loading}>
							Ajouter une sélection
						</Button>
					</Link>
				</div>
			</div>
			<Table
				error={error}
				loading={loading}
				noResultMessage={'Aucune sélection'}
				columns={[
					{ content: 'picto', center: true },
					{ content: 'nom', center: true },
					{ content: 'Supprimer', center: true, isButton: true },
				]}
				rows={selections.map(({ id, nom, pictoSrc }) => {
					const img = pictoSrc.includes('/media') ? (
						<img alt="" src={pictoSrc} className={styles.photo} />
					) : (
						<div>
							<Iframe embedVal={pictoSrc} />
						</div>
					);

					return {
						id,
						mobileTitle: (
							<div className={styles.mobileTitle}>{french(nom)}</div>
						),
						cells: [
							{ content: img },
							{ content: nom },
							{
								content: (
									<BtnPoubelle
										title="Supprimer la sélection"
										onClick={() => deleteSelection(id)}
									/>
								),
							},
						],
					} as RowProps;
				})}
			/>
		</TableWrapper>
	);
};

const mapStateToProps = (state: AppState) => ({
	fournisseurId: state.compte.id,
});

export default withRouter(connect(mapStateToProps)(MesSelections));
