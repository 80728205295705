import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import request from '../../../utils/request';
import styles from './FormulaireCategorie.module.scss';
import { Categorie, SousCategorie } from '../../../types/Categorie';

import { SubmitHandler, useForm } from 'react-hook-form';
import Input from '../Input/Input';
import Button from '../../Common/Button/Button';
import Dropzone from '../../Common/Dropzone/Dropzone';
import ErrorMessage from '../../Common/Form/ErrorMessage/ErrorMessage';
import { AppState } from '../../../store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SousCategories from './SousCategorie/SousCategorie';
import { useToasts } from 'react-toast-notifications';

const upsertCategorieMutation = loader('./mutations/upsertCategorie.graphql');
const upsertManySousCategorieMutation = loader(
	'./mutations/upsertManySousCategorie.graphql',
);

interface FormValues {
	nom: string;
	code: string;
	categorie: string;
	sousCategorie: string;
	image: FileList;
	labels: string[];
	tarification: String;
	origine: string;
	unite: 'KG' | 'COLIS' | 'PIECE' | 'LOT';
	auCour: boolean;
	disabled: boolean;
}
interface FormulaireProduitProps extends RouteComponentProps {
	data?: Categorie;
	type?: 'create' | 'update';
	fournisseurId: string;
}

function FormulaireCategorie({
	data,
	type,
	fournisseurId,
}: FormulaireProduitProps) {
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);
	const [sousCategories, setSousCategories] = useState<SousCategorie[]>([]);
	const [error, setError] = useState('');
	const [imageCategorieSrc, changeImageCategorieSrc] = useState('');
	const [imageCategorie, setImageCategorie] = useState<File | null>(null);
	const [categorieId, setCategorieId]: any = useState('');

	const {
		register,
		errors,
		handleSubmit,
		reset,
		setValue,
		getValues,
	} = useForm<FormValues>();

	useEffect(() => {
		if (data) {
			const sousCategories = data?.sousCategories;
			setSousCategories(sousCategories);
			setCategorieId(data.id);
			reset({
				nom: data?.nom,
			});
		}
	}, [data, reset]);
	function addSousCategorie() {
		const value = getValues('sousCategories');
		setSousCategories([
			...sousCategories,
			{
				nom: value,
			} as SousCategorie,
		]);
	}
	const resetForm = () => {
		setValue('nom', undefined);
		setSousCategories([]);
		setValue('sousCategories', []);
		setValue('image', undefined);
		setImageCategorie(null);
		changeImageCategorieSrc('');
	};
	const onSubmitUpsert: SubmitHandler<FormValues> = async (
		data: FormValues,
	) => {
		const { nom } = data;

		try {
			setError('');
			setLoading(true);
			const { upsertCategorieWithFournisseur } = await request(
				upsertCategorieMutation,
				{
					id: categorieId,
					fournisseur: fournisseurId,
					nom,
					picto: imageCategorie,
				},
			);
			const completeSousCategories = sousCategories.map(
				(sousCategorie: SousCategorie) => ({
					...sousCategorie,
					fournisseur: fournisseurId,
					categorie: upsertCategorieWithFournisseur.id,
				}),
			);

			for (const sousCategorie of completeSousCategories) {
				await request(upsertManySousCategorieMutation, {
					fournisseurId: sousCategorie.fournisseur,
					categorieId: sousCategorie.categorie,
					nom: sousCategorie.nom,
				});
			}

			addToast(
				`La catégorie a été ${type === 'create' ? 'ajoutée.' : 'modifiée.'}`,
				{
					appearance: 'success',
					autoDismiss: true,
				},
			);
			if (type === 'create') {
				resetForm();
			}
			setLoading(false);
		} catch (err) {
			setError(err.message);
			setLoading(false);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmitUpsert)} className={styles.form}>
			<div className={styles.container}>
				<div className={styles.table1}>
					<h1 className={styles.tableTitle}>Description categorie</h1>
					<div>
						<Input
							label="Nom de la catégorie: *"
							name="nom"
							type="text"
							className=""
							register={register({ required: true })}
							readOnly={false}
						/>
						{errors.nom && (
							<ErrorMessage>
								{'Veuillez indiquer un nom de produit'}
							</ErrorMessage>
						)}
					</div>
					<div className={styles.photo}>
						<label>Image *</label>
						<Dropzone
							imageSrc={imageCategorieSrc}
							imageWidthToDisplay={600}
							onChange={({ tempURL, file }) => {
								setImageCategorie(file);
								changeImageCategorieSrc(tempURL);
							}}
							register={register}
							name="image"
						/>
					</div>

					<h1 className={styles.tableTitle}>Description sous-categorie</h1>
					<div>
						<div>
							<Input
								label="Nom de la sous-catégorie: *"
								name="sousCategories"
								type="text"
								className=""
								register={register()}
								readOnly={false}
							/>
						</div>
					</div>
					<Button
						onClick={() => addSousCategorie()}
						className={styles.addSousCategorie}
					>
						Ajouter la sous-categorie
					</Button>
					<SousCategories sousCategories={sousCategories} />
					<ErrorMessage>{error}</ErrorMessage>

					<Button submit className={styles.btnSubmit} loading={loading}>
						{type === 'create' ? 'Ajouter' : 'Enregistrer les modifications'}
					</Button>
				</div>
			</div>
		</form>
	);
}

function mapStateToProps(state: AppState) {
	return { fournisseurId: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(FormulaireCategorie));
