import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import style from './DropzonePDF.module.scss';
import PdfViewer from '../PdfViewer/PdfViewer';

interface Props {
	onFileSelect: (file: File | null) => void;
	defaultPdfPath?: string | null;
}

const DropzonePDF = ({ onFileSelect, defaultPdfPath }: Props) => {
	const acceptedFile = 'application/pdf';
	const [isDragging, setIsDragging] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const [path, setPath] = useState<string | null>(null); // State to handle the PDF URL or file
	const [isHovered, setIsHovered] = useState(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	let timeoutId: NodeJS.Timeout | null = null;

	const handleFile = (selectedFile: File | null) => {
		if (selectedFile?.type === acceptedFile) {
			setFile(selectedFile);
			onFileSelect(selectedFile);
			setPath(URL.createObjectURL(selectedFile));
		}
	};

	const handleDragState = (hover: boolean, drag: boolean) => {
		setIsHovered(hover);
		setIsDragging(drag);
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		handleDragState(true, true);
	};

	const handleDragLeave = () => handleDragState(false, false);

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		handleDragState(false, false);
		handleFile(event.dataTransfer?.files[0] || null);
	};

	const handleSelectedFile = (event: ChangeEvent<HTMLInputElement>) => {
		handleFile(event.target.files?.[0] || null);
	};

	const handleSelectOrOpenPdf = () => {
		if (!file) {
			selectPdf();
			return;
		}

		if (timeoutId) {
			clearTimeout(timeoutId);
			openPdf();
		} else {
			timeoutId = setTimeout(() => {
				selectPdf();
				timeoutId = null;
			}, 200);
		}
	};

	const openPdf = () => {
		if (path) {
			const newWindow = window.open(path, '_blank');
			newWindow?.focus();
		}
	};

	const selectPdf = () => {
		fileInputRef.current?.click();
	};

	useEffect(() => {
		if (defaultPdfPath) {
			setPath(defaultPdfPath);
			setFile(new File([], ''));
		}
	}, [defaultPdfPath]);

	return (
		<div className={style.container}>
			<PdfViewer pdfPath={path} />
			<div
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
				onClick={handleSelectOrOpenPdf}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				className={`${style.dropzone} ${isDragging ? style.dragging : ''} ${
					file || path ? style['has-pdf'] : ''
				}`}
				style={{
					zIndex: isHovered || !(file || path) ? 2 : 1,
					opacity: isHovered || !(file || path) ? 1 : 0,
				}}
			>
				<div className={style.text}>
					{isDragging ? (
						'Et on relâche...'
					) : (
						<>
							<div>cliquer ou glisser-déposer un PDF ici</div>
							{(file || path) && (
								<>
									<div>ou</div>
									<div>double cliquer pour ouvrir le PDF</div>
								</>
							)}
						</>
					)}
				</div>
			</div>
			<input
				type="file"
				accept={acceptedFile}
				onChange={handleSelectedFile}
				style={{ display: 'none' }}
				ref={fileInputRef}
				multiple={false}
			/>
		</div>
	);
};

export default DropzonePDF;
