import formatDate from 'dateformat';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Table from '../../../Common/Table/Table';
import { RowProps } from '../../../Common/Table/types';
import { QueryResponse } from '../Produits';
import Img from '../../../Common/Img/Img';
import { photosProduitsSizes } from '../../../../utils/imgSizes';
import BtnEdit from '../../../Common/BtnEdit/BtnEdit';
import BtnPoubelle from '../../../Common/BtnPoubelle/BtnPoubelle';

interface Props extends RouteComponentProps {
	error?: string;
	loading: boolean;
	noResultMessage: string;
	produits: QueryResponse['produits'];
	deleteClient: (id: string) => void;
	updateProduit: (id: string) => void;
}

export function _ProduitsListe({
	error,
	loading,
	produits,
	noResultMessage,
	deleteClient,
	updateProduit,
}: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage={noResultMessage}
			columns={[
				{ content: 'Image', center: true },
				{ content: 'Nom', center: true },
				{ content: 'Date de création', center: true },
				{ content: 'Date de mise à jour', center: true },
				{ content: 'Code', center: true },
				{ content: 'Unite', center: true },
				{ content: 'Tarif', center: true },
				{ content: 'Edit', isButton: true, center: true },
				{ content: 'Actif', center: true },
				{ content: 'Supprimer', center: true, isButton: true },
			]}
			rows={produits.map(
				(
					{
						id,
						nom,
						code,
						unite,
						createdAt,
						updatedAt,
						disabled,
						tarifs,
						imageSrc,
					},
					i,
				) => {
					return {
						id,
						cells: [
							{
								content: (
									<div>
										<Img
											src={imageSrc}
											alt={nom}
											fallback="jpg"
											otherSizes={photosProduitsSizes}
											mediaQueries={[
												{ breakpoint: '<=mobile', width: 100 },
												{ breakpoint: '<=tablet', width: 260 },
												{ breakpoint: '<=laptop', width: 360 },
												{ breakpoint: '>laptop', width: 100 },
											]}
										/>
									</div>
								),
							},
							{
								content: nom,
							},
							{ content: formatDate(createdAt, 'dd/mm/yyyy') },
							{ content: formatDate(updatedAt, 'dd/mm/yyyy') },
							{
								content: code,
							},
							{
								content: unite,
							},
							{
								content: tarifs.length > 0 ? tarifs[0].prix + '€' : null,
							},
							{
								content: (
									<BtnEdit
										title="Edition"
										onClick={() => {
											updateProduit(id);
										}}
									/>
								),
							},
							{
								content: disabled ? 'Inactif' : 'Actif',
							},
							{
								stopPropagation: true,
								content: (
									<BtnPoubelle
										title="Supprimer l'actualité"
										onClick={() => deleteClient(id)}
									/>
								),
							},
						],
					} as RowProps;
				},
			)}
		/>
	);
}

export default withRouter(_ProduitsListe);
