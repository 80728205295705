import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import request from '../../../utils/request';
import Logo from '../Logo/Logo';
import styles from './Validate.module.scss';

export interface Props {
	commande: string;
}

const queryActu = loader('./queries/validateCommande.graphql');
const loginWithId = loader('./queries/loginWithId.graphql');

function ValidateCommande({ commande }: Props) {
	// const [string, setString] = useState('');
	// const [fourni, setFourni] = useState('');

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const fourni = commande.substr(
			commande.indexOf('/fournisseur/') + 13,
			commande.length,
		);
		const cmd = commande.substr(19, fourni.length);
		// setFourni(fourni);
		// setString(cmd.toString());

		type TResponse = { commandeValidate: { lignes: [] } };
		type TResponse2 = { loginWithId: { id: string } };
		if (!window.location.href.includes('validate=true')) {
			request<TResponse2>(loginWithId, {
				id: fourni,
				model: 'Fournisseur',
			}).then((es) => {
				window.location.href = window.location.href + '?validate=true';
			});
		}

		if (window.location.href.includes('validate=true')) {
			request<TResponse>(queryActu, { id: cmd }).then((resQuery) => {
				setLoading(false);
				window.location.href =
					'/commande/' + cmd.toString() + '?from=/commandes';
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className={styles.content}>
			<Logo />
			<h1 className={styles.title}>
				{!loading ? 'Commande en cours de préparation ! ' : '...'}
			</h1>
		</div>
	);
}

export default ValidateCommande;
