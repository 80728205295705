import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Table from '../../../Common/Table/Table';
import { RowProps } from '../../../Common/Table/types';
import { QueryResponse } from '../Categorie';
import BtnEdit from '../../../Common/BtnEdit/BtnEdit';
import BtnPoubelle from '../../../Common/BtnPoubelle/BtnPoubelle';
import styles from '../../ProduitsSelection/ProduitsSelection.module.scss';
import request from '../../../../utils/request';
import { loader } from 'graphql.macro';

const deleteCategorieReq = loader('./deleteCategorie.graphql');

interface Props extends RouteComponentProps {
	error?: string;
	loading: boolean;
	noResultMessage: string;
	categorie: QueryResponse['categories'];
	updateCategorie: (id: string) => void;
}

export function _ProduitsListe({
	error,
	loading,
	categorie,
	noResultMessage,
	updateCategorie,
}: Props) {
	const [categories, setCategories] = useState<QueryResponse['categories']>([]);

	const deleteCategorie = (id: string) => {
		request(deleteCategorieReq, { id: id }).then(() => {
			const categories = categorie.filter((cat) => cat.id !== id);
			setCategories(categories);
		});
	};

	useEffect(() => {
		setCategories(categorie);
	}, [categorie]);

	useEffect(() => {}, [categories]);

	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage={noResultMessage}
			columns={[
				{ content: 'Image', center: true },
				{ content: 'Nom', center: true },
				{ content: 'Edit', isButton: true, center: true },
				{ content: 'Supprimer', center: true, isButton: true },
			]}
			rows={categories.map(({ id, nom, createdAt, updatedAt, pictoSrc }, i) => {
				const img = <img alt="" src={pictoSrc} className={styles.photo} />;

				return {
					id,
					cells: [
						{ content: img },
						{
							content: nom,
						},
						{
							content: (
								<BtnEdit
									title="Edition"
									onClick={() => {
										updateCategorie(id);
									}}
								/>
							),
						},
						{
							stopPropagation: true,
							content: (
								<BtnPoubelle
									title="Supprimer la catégorie"
									onClick={() => deleteCategorie(id)}
								/>
							),
						},
					],
				} as RowProps;
			})}
		/>
	);
}

export default withRouter(_ProduitsListe);
