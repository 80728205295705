import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import {
	Link,
	withRouter,
	RouteComponentProps,
	Redirect,
} from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import request from '../../../utils/request';
import Button from '../../Common/Button/Button';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './Produits.module.scss';
import { Produit, Fournisseur } from '../../../types';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import ProduitsListe from './ProduitsListe/ProduitsListe';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import OrderBy from '../../Common/OrderBy/OrderBy';
import TablePagination from '../../Common/Table/TablePagination/TablePagination';
const queryProduits = loader('./getProduits.graphql');
const mutationDeleteProduit = loader('./deleteProduit.graphql');

export interface Client {
	id: string;
	enseigne: string;
	login: string;
	email: string;
	description: string;
	photo: string;
	edit: string;
	disabled: string;
	imageSrc: string;
}
export interface QueryResponse {
	countProduits: number;
	produits: Produit[];
}
export interface Props extends RouteComponentProps {
	idFournisseur: Fournisseur['id'];
}
const itemsPerPage = 10;

function _Produits({ idFournisseur, location: { search, pathname } }: Props) {
	useTitle('Mes produits');

	const [produits, setProduits] = useState<QueryResponse['produits']>([]);

	const [countTotal, setCountTotal] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const searchParams = new URLSearchParams(search);
	const pageIndex = +(searchParams.get('page') || 1);
	const orderBy = searchParams.get('orderBy') || '';
	const order = searchParams.get('order') || '';

	useEffect(() => {
		if (!orderBy || !order) return;
		let isMounted = true;
		setLoading(true);
		setError('');

		const params = {
			idFournisseur,
			where: {
				fournisseur: { id: idFournisseur },
			},
			first: itemsPerPage,
			orderBy: `${orderBy}_${order}`,
			skip: itemsPerPage * (pageIndex - 1),
		};

		request<QueryResponse>(queryProduits, params)
			.then((res) => {
				isMounted && setProduits(res.produits);
				isMounted && setCountTotal(res.countProduits);
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idFournisseur, order, orderBy, pageIndex]);

	if (!orderBy || !order) {
		return <Redirect to={`${pathname}?orderBy=updatedAt&order=DESC`} />;
	}

	function deleteClient(id: string) {
		setError('');

		request(mutationDeleteProduit, { id })
			.then(() => setProduits(produits.filter((a) => a.id !== id)))
			.catch((err) => setError(err.message));
	}

	async function updateProduit(id: string) {
		setError('');
		window.location.href = `/produit/edition/${id}`;
	}

	return (
		<TableWrapper>
			<div>
				<div className={styles.buttonWrapper}>
					<Link to={{ pathname: '/produit/create' }}>
						<Button submit loading={loading}>
							Ajouter un produit
						</Button>
					</Link>
				</div>
			</div>
			<TableHeader>
				<OrderBy
					fields={[
						{ name: 'Date de création', slug: 'createdAt' },
						{ name: 'Date de mise à jour', slug: 'updatedAt' },
						{ name: 'Nom', slug: 'nom' },
						{ name: 'Code', slug: 'code' },
					]}
				/>
				{/* <FilterByDateRange label="Date de livraison" /> */}
			</TableHeader>
			<ProduitsListe
				error={error}
				loading={loading}
				noResultMessage={"Vous n'avez aucun produit"}
				produits={produits}
				deleteClient={deleteClient}
				updateProduit={updateProduit}
			/>
			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={countTotal}
				itemsCountPerPage={itemsPerPage}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { idFournisseur: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(_Produits));
