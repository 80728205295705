import React from 'react';
import { NavLink } from 'react-router-dom';
import SideMenuWrapper from '../../Common/SideMenuWrapper/SideMenuWrapper';
import styles from './SideMenu.module.scss';

const pages: { title: string; path: string; isSub?: boolean }[] = [
	// { title: 'Accueil', path: '/' },
	{ title: 'Commandes', path: '/commandes' },
	{ title: 'Compte', path: '/compte' },
	{ title: 'Produits', path: '/produits/liste' },
	{ title: 'Ajouter produit', path: '/produit/create', isSub: true },
	{ title: 'Voir mes produits', path: '/produits/liste', isSub: true },
	{ title: 'Mes sélection', path: '/mes-selection' },
	{ title: 'Ajouter une sélection', path: '/selection/create', isSub: true },
	{ title: 'Mes catégories', path: '/categories' },
	{ title: 'Ajouter une catégorie', path: '/categorie/create', isSub: true },
	{ title: 'Clients', path: '/clients' },
	{ title: 'Actualités', path: '/actualites' },
];

function SideMenu() {
	return (
		<SideMenuWrapper>
			{pages.map((p, i) => (
				<NavLink
					exact
					key={i}
					to={p.path}
					children={p.title}
					className={`${styles.link} ${p.isSub ? styles.sub_link : ''}`}
					activeClassName={styles.active}
				/>
			))}
		</SideMenuWrapper>
	);
}

export default SideMenu;
