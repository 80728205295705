import formatDate from 'dateformat';
import React from 'react';
import { french, thinSpace } from '../../../../utils/french';
import BtnPoubelle from '../../../Common/BtnPoubelle/BtnPoubelle';
import Table from '../../../Common/Table/Table';
import { RowProps } from '../../../Common/Table/types';
import { Actualite } from '../Actualites';
import styles from './ListeActualites.module.scss';
import Iframe from '../../../Common/Iframe/Iframe';

interface Props {
	actualites: Actualite[];
	error?: string;
	loading: boolean;
	deleteActu: (id: string) => void;
	/** Permet d'inverser l'ordre de deux actus */
	invertActus: (index1: number, index2: number) => void;
}

function ListeActualites({
	actualites,
	error,
	loading,
	deleteActu,
	invertActus,
}: Props) {
	const minOrder = Math.min(...actualites.map((a) => a.ordre));
	const maxOrder = Math.max(...actualites.map((a) => a.ordre));

	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage={'Aucune actualité'}
			columns={[
				{ content: 'Ordre', center: true, isButton: true },
				{ content: 'Image', center: true, mobileHidden: true },
				{ content: 'Date de création' },
				{ content: 'Titre', mobileHidden: true },
				{ content: 'Contenu' },
				{ content: 'Url' },
				{ content: 'Supprimer', center: true, isButton: true },
			]}
			rows={actualites.map(
				({ contenu, createdAt, id, imageSrc, ordre, titre, url }, i) => {
					const img = imageSrc.includes('/media') ? (
						<img alt="" src={imageSrc} className={styles.photo} />
					) : (
						<div>
							<Iframe embedVal={imageSrc} />
						</div>
					);
					return {
						id,
						mobileTitle: (
							<div className={styles.mobileTitle}>
								{img}
								{french(titre)}
							</div>
						),
						href: '/actualites/edition/' + id,
						cells: [
							{
								stopPropagation: true,
								content: (
									<>
										{ordre > minOrder && (
											<button
												className={[styles.orderBtn, styles.up].join(' ')}
												onClick={() => invertActus(i, i - 1)}
											/>
										)}
										{ordre < maxOrder && (
											<button
												className={[styles.orderBtn, styles.down].join(' ')}
												onClick={() => invertActus(i, i + 1)}
											/>
										)}
									</>
								),
							},
							{ content: img },
							{
								content: formatDate(
									createdAt,
									`dd/mm/yyyy à HH${thinSpace}"h"${thinSpace}MM`,
								),
							},
							{ content: french(titre), className: styles.actuTitle },
							{ content: french(contenu), className: styles.actuContent },
							{ content: url },
							{
								stopPropagation: true,
								content: (
									<BtnPoubelle
										title="Supprimer l'actualité"
										onClick={() => deleteActu(id)}
									/>
								),
							},
						],
					} as RowProps;
				},
			)}
		/>
	);
}

export default ListeActualites;
