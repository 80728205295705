import React, { ComponentProps } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import Contact from '../../Common/Contact/Contact';
import Gradient from '../../Common/Gradient/Gradient';
import MentionsLegales from '../../Common/MentionsLegales/MentionsLegales';
import Admins from '../Admins/Admin';
import Alertes from '../Alertes/Alertes';
import Clients from '../Clients/Clients';
import Commentaires from '../Commentaires/Commentaires';
import CreationActualite from '../CreationActualite/CreationActualite';
import CreationAdmin from '../CreationAdmin/CreationAdmin';
import CreationClient from '../CreationClient/CreationClient';
import CreationFournisseur from '../CreationFournisseur/CreationFournisseur';
import EditionActualite from '../EditionActualite/EditionActualite';
import EditionAdmin from '../EditionAdmin/editionAdmin';
import EditionClient from '../EditionClient/editionClient';
import EditionFournisseur from '../EditionFournisseur/editionFournisseur';
import Fournisseurs from '../Fournisseur/Fournisseur';
import styles from './Routes.module.scss';

const switchRoutes: {
	path: string;
	component: ComponentProps<typeof Route>['children'];
}[] = [
	{ path: '/mentions-legales', component: <MentionsLegales /> },
	{ path: '/contact', component: <Contact /> },
	{ path: '/admin/list', component: <Admins /> },
	// { path: '/actualites', component: <Actualites /> },
	{ path: '/admin/creation', component: <CreationAdmin /> },
	// { path: '/admin/edition/:id', component: <CreationAdmin /> },

	{ path: '/admin/clients', component: <Clients /> },

	{ path: '/admin/fournisseurs', component: <Fournisseurs /> },
	{ path: '/admin/commentaires', component: <Commentaires /> },
	{ path: '/admin/alertes', component: <Alertes /> },

	{ path: '/actualites/creation', component: <CreationActualite /> },
	{ path: '/client/creation', component: <CreationClient /> },
	{ path: '/fournisseur/creation', component: <CreationFournisseur /> },

	{
		path: '/fournisseur/edition/:id',
		component: ({ match }) => <EditionFournisseur id={match!.params.id} />,
	},
	{
		path: '/actualites/edition/:id',
		component: ({ match }) => <EditionActualite id={match!.params.id} />,
	},
	{
		path: '/client/edition/:id',
		component: EditionClient,
	},
	{
		path: '/fournisseur/edition/:id',
		component: ({ match }) => <EditionFournisseur id={match!.params.id} />,
	},
	{
		path: '/admin/edition/:id',
		component: ({ match }) => <EditionAdmin id={match!.params.id} />,
	},
];

function Routes() {
	const scrollableDiv = useScrollToTop();

	return (
		<main className={styles.main}>
			<Gradient />

			<div className={styles.scrollable} ref={scrollableDiv}>
				{/*<Route path="/actualites">*/}
				{/*	<Onglets pages={[{ title: 'Actualités', to: '/actualites' }]} />*/}
				{/*</Route>*/}

				<Switch>
					{switchRoutes.map(({ component, path }, i) => (
						<Route key={i} path={path} exact>
							{component}
						</Route>
					))}
					<Redirect to="/admin/fournisseurs" />
				</Switch>
			</div>
		</main>
	);
}

export default Routes;
