import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TableWrapper from '../../../Common/Table/TableWrapper/TableWrapper';
import { Categorie } from '../../../../types';
import SousCategorieListe from './SousCategorieListe/SousCategorieListe';
import TableHeader from '../../../Common/Table/TableHeader/TableHeader';

export interface QueryResponse {
	countCategories: number;
	sousCategories: any[];
}
export interface Props extends RouteComponentProps {
	categorieId?: Categorie['id'];
	sousCategories?: any;
}

function SousCategories({ categorieId, sousCategories }: Props) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		setLoading(true);
		setError('');
		if (categorieId) {
		} else {
			setLoading(false);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<TableWrapper>
			<TableHeader>
				<></>
			</TableHeader>
			<SousCategorieListe
				error={error}
				loading={loading}
				noResultMessage={"Vous n'avez aucune sous categorie"}
				sousCategories={sousCategories}
			/>
		</TableWrapper>
	);
}

export default withRouter(SousCategories);
