import React from 'react';

import { useTitle } from '../../../hooks/useTitle';
import styles from './CreationCategorie.module.scss';

import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import BtnBack from '../../Common/BtnBack/BtnBack';
import FormulaireCategorie from '../FormulaireCategorie/FormulaireCategorie';

function CreationCategorie() {
	useTitle('Nouvelle Categorie');

	return (
		<TableWrapper className={styles.wrapper}>
			<TableHeader className={styles.wrapperHeader}>
				<BtnBack to="/categories" className={styles.btnBack} withText />
				<p className={styles.title}>Création d'une Categorie</p>
			</TableHeader>
			<div className={styles.formContainer}>
				<FormulaireCategorie type="create" />
			</div>
		</TableWrapper>
	);
}

export default CreationCategorie;
