import React from 'react';
import style from './PdfViewer.module.scss';

type Props = {
	pdfPath: string | null;
};

const PdfViewer = ({ pdfPath }: Props) => {
	return pdfPath ? (
		<iframe className={style.container} src={pdfPath} title="PDF Viewer" />
	) : null;
};

export default PdfViewer;
