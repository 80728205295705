import React, { ComponentProps } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import Contact from '../../Common/Contact/Contact';
import Error404 from '../../Common/Error404/Error404';
import Gradient from '../../Common/Gradient/Gradient';
import MentionsLegales from '../../Common/MentionsLegales/MentionsLegales';
import Onglets from '../../Common/Onglets/Onglets';
import Commandes from '../Commandes/Commandes';
import CompteFournisseur from '../CompteFournisseur/CompteFournisseur';
import DetailCommande from '../DetailCommande/DetailCommande';
import styles from './Routes.module.scss';
import CreationProduit from '../CreationProduit/CreationProduit';
import Produits from '../Produits/Produits';
import EditionProduit from '../EditionProduit/EditionProduit';
import CreationClient from '../../Admin/CreationClient/CreationClient';
import Clients from '../../Admin/Clients/Clients';
import EditionClient from '../../Admin/EditionClient/editionClient';
import Actualites from '../../Admin/Actualites/Actualites';
import CreationActualite from '../../Admin/CreationActualite/CreationActualite';
import EditionActualite from '../../Admin/EditionActualite/EditionActualite';
import MesSelections from '../ProduitsSelection/MesSelections';
import CreateSelection from '../CreateSelection/CreateSelection';
import CreationCategorie from '../CreationCategorie/CreationCategorie';
import Categorie from '../Categorie/Categorie';
import EditionCategorie from '../EditionCategorie/EditionCategorie';

export const switchRoutes: {
	path: string;
	exact?: boolean;
	component: ComponentProps<typeof Route>['children'];
}[] = [
	{ path: '/mentions-legales', component: <MentionsLegales /> },
	{ path: '/commandes', component: <Commandes /> },
	{ path: '/contact', component: <Contact /> },
	{ path: '/commande/:id', component: <DetailCommande /> },
	{ path: '/compte', component: <CompteFournisseur /> },
	{ path: '/produit/create', component: <CreationProduit /> },
	{ path: '/produits/liste', component: <Produits /> },
	{ path: '/mes-selection', component: <MesSelections /> },
	{ path: '/selection/create', component: <CreateSelection /> },
	{ path: '/categories', component: <Categorie /> },
	{ path: '/categorie/create', component: <CreationCategorie /> },
	{ path: '/clients', component: <Clients /> },
	{ path: '/client/creation', component: <CreationClient /> },
	{ path: '/client/edition/:id', component: <EditionClient /> },
	{ path: '/actualites', component: <Actualites />, exact: true },
	{
		path: '/actualites/creation',
		component: <CreationActualite />,
		exact: true,
	},
	{
		path: '/actualites/edition/:id',
		component: ({ match }) => <EditionActualite id={match!.params.id} />,
	},
	{
		path: '/produit/edition/:id',
		component: ({ match }) => <EditionProduit id={match!.params.id} />,
	},
	{
		path: '/categorie/edition/:id',
		component: ({ match }) => <EditionCategorie id={match!.params.id} />,
	},
	{
		path: '/actualites/creation',
		component: <CreationActualite />,
		exact: true,
	},
];

function Routes() {
	const scrollableDiv = useScrollToTop();

	return (
		<main className={styles.main}>
			<Gradient />

			<div className={styles.scrollable} ref={scrollableDiv}>
				<Route path={['/commandes', '/commande/:id']}>
					<Onglets
						pages={[
							{
								to: '/commandes',
								title: 'Mes commandes',
								subRoutes: ['/commande/:id'],
							},
						]}
					/>
				</Route>

				<Switch>
					{switchRoutes.map(({ component, path, exact }, i) => (
						<Route key={i} path={path} exact={exact}>
							{component}
						</Route>
					))}

					<Route>
						<Redirect to="/commandes" />
					</Route>

					<Route>
						<Error404 />
					</Route>
				</Switch>
			</div>
		</main>
	);
}

export default Routes;
