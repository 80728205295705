import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store';
import {
	changeLignePanier,
	setGlobalCommentForFournisseur,
} from '../../../store/panier/actions';
import { StatutCommandeLigne } from '../../../types';
import { convertStatus, thinSpace } from '../../../utils/french';
import { loadStateFromLocalStorage } from '../../../utils/localStorage';
import BtnCommentaire from '../../Common/Commentaire/BtnCommentaire/BtnCommentaire';
import EditGlobalCommentaire from '../../Common/EditGobalCommentaire/EditGlobalCommentaire';
import LivraisonDate from '../../Common/LivraisonDate/LivraisonDate';
import styles from './HeaderLignesFournisseur.module.scss';

interface Props {
	enseigne: string;
	dateLivraison: string | null;
	statut?: StatutCommandeLigne;
	id: string;
	optionLivraison: any;
	panier: any;
	dayDelivery: any;
	closedDay: any;
	timeLivraison: any;
	setGlobalCommentForFournisseur: typeof setGlobalCommentForFournisseur;
}

export function _HeaderLignesFournisseur({
	closedDay,
	id,
	panier,
	setGlobalCommentForFournisseur,
	dayDelivery,
	optionLivraison,
	timeLivraison,
	enseigne,
	dateLivraison,
	statut,
}: Props) {
	const localPanier = loadStateFromLocalStorage('panier');
	const commE = localPanier?.comments.find((e) => e.fournisseur === id);
	const [comm, setCommVal] = useState(commE ? commE.content : '');
	let dateLivraisonVal = new Date();
	const now = new Date().getHours();
	// const [choose, setChoose] = useState(false);
	// console.log(new Date(panier.dateLivraison), 'LOLOL');
	const days = [
		'DIMANCHE',
		'LUNDI',
		'MARDI',
		'MERCREDI',
		'JEUDI',
		'VENDREDI',
		'SAMEDI',
	];
	const disabled = [
		'LUNDI',
		'MARDI',
		'MERCREDI',
		'JEUDI',
		'VENDREDI',
		'SAMEDI',
		'DIMANCHE',
	];

	const posible = dayDelivery
		.split(',')
		.map((e: string) => {
			if (disabled.includes(e)) {
				return e;
			}
			return null;
		})
		.filter((e: any) => e !== undefined);

	if (!optionLivraison || !timeLivraison) {
		dateLivraisonVal = new Date(panier.dateLivraison);
	} else {
		if (now > timeLivraison) {
			dateLivraisonVal = addDays(
				new Date(panier.dateLivraison),
				parseInt(optionLivraison) + 1,
			);
			// setChoose(true);
		} else {
			dateLivraisonVal = addDays(
				new Date(panier.dateLivraison),
				parseInt(optionLivraison),
			);
			// setChoose(true);
		}
	}

	const [dateDIsplay, setDateDisplay] = useState(dateLivraisonVal);

	// const [disabledDate, setDisabledDate] = useState(returnDateNotInArray(diabled.split(' ')).map((e) => {
	// 	return returnDateFormAcronyme(e.toString());
	// }).flat());

	useEffect(() => {
		// check();
		const day = days[dateDIsplay.getDay()];
		if (!posible.includes(day)) {
			// const date = addDays(dateDIsplay, 1);
			// setDateDisplay(date);
		}
	}, [days, dateDIsplay, posible]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function check() {
		if (dateDIsplay.toString() === 'Invalid Date') {
			setInterval(() => {
				const panier = loadStateFromLocalStorage('panier');
				if (panier?.dateLivraison) {
					setDateDisplay(new Date(panier.dateLivraison));
				}
			}, 1000);
		}
	}

	function setComm(value: any) {
		setGlobalCommentForFournisseur(id, value);
	}

	function handleValue(value: any) {
		setCommVal(value);
	}

	const [open, setOpen] = useState(0);
	return (
		<div className={styles.wrapper}>
			<p className={styles.fournisseur}>
				Fournisseur{thinSpace}: <strong>{enseigne}</strong>
			</p>

			<p className={styles.productNotFound}>
				{comm.length ? (
					<>
						<BtnCommentaire
							hasValue={true}
							title={'Commentaire global'}
							onClick={() => setOpen(1)}
						/>
					</>
				) : (
					<></>
				)}
				Vous ne trouvez pas votre produit ?{' '}
				<strong onClick={() => setOpen(1)}>Cliquez ici</strong>
			</p>

			<LivraisonDate
				closedDay={closedDay}
				dayDelivery={dayDelivery}
				timeLivraison={timeLivraison}
				optionLivraison={optionLivraison}
				panier={panier}
			/>

			{statut && (
				<p className={styles.statut}>
					Statut{thinSpace}: <strong>{convertStatus(statut)}</strong>
				</p>
			)}
			{open ? (
				<EditGlobalCommentaire
					input={comm}
					handleVal={(res: any) => handleValue(res)}
					commanHandler={() => setComm(comm)}
					close={() => setOpen(0)}
				/>
			) : (
				<></>
			)}
		</div>
	);
}

function mapStateToProps(state: AppState) {
	return { lignesPanier: state.panier.lignes };
}

export default connect(mapStateToProps, {
	changeLignePanier,
	setGlobalCommentForFournisseur,
})(_HeaderLignesFournisseur);
