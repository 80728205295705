import React, { useEffect, useState } from 'react';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import OrderBy from '../../Common/OrderBy/OrderBy';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import FilterByDateRange from '../../Common/FilterByDateRange/FilterByDateRange';
import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TablePagination from '../../Common/Table/TablePagination/TablePagination';
import { User } from '../../../store/compte/types';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import { downloadManyFiles } from '../../../utils/download';
import ExportSelectedButton from '../../Common/ExportSelectedButton/ExportSelectedButton';
import styles from './MesAvoirs.module.scss';
import ListeAvoirs from './ListeAvoirs/ListeAvoirs';

const avoirsQuery = loader('./getAvoirs.graphql');
const avoirsByClientIdQuery = loader('./getAvoirsByClientIdQuery.graphql');

export type Avoir = {
	id: string;
	dateEmission: string;
	numeroAvoir: string;
	pdfPath: string;
	pdfName: string;
};

interface AvoirsResponse {
	avoirs: Avoir[];
	countAvoirs: number;
}

type Clauses = {
	where: {};
	skip: number;
	first: number;
	orderBy: string;
};

export interface Props extends RouteComponentProps {
	clientId: User['id'];
}

const MesAvoirs = ({ location, clientId }: Props) => {
	const ITEMS_PER_PAGE = 10;
	const [avoirs, setAvoirs] = useState<Avoir[]>([]);
	const [countAvoirs, setCountAvoirs] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [fields] = useState([
		{ name: `Date d'émission`, slug: 'dateEmission' },
		{ name: `Numéro d'avoir'`, slug: 'numeroAvoir' },
	]);

	const setClauses = (): Clauses => {
		const searchParams = new URLSearchParams(location.search);
		const orderBy = searchParams.get('orderBy') || fields[0].slug;
		const order = searchParams.get('order') || 'ASC';
		const dateStart = searchParams.get('dateStart');
		const dateEnd = searchParams.get('dateEnd');
		const pageIndex = Number(searchParams.get('page') || 1);

		return {
			where: {
				client: { id: clientId },
				...(dateStart && { dateEmission_gte: dateStart }),
				...(dateEnd && { dateEmission_lte: dateEnd + 'T23:59:59.999Z' }),
			},
			skip: ITEMS_PER_PAGE * (pageIndex - 1),
			first: ITEMS_PER_PAGE,
			orderBy: `${orderBy}_${order}`,
		};
	};

	const fetchAvoirs = async (clauses: Clauses) => {
		request<AvoirsResponse>(avoirsQuery, clauses)
			.then((avoirsResponse) => {
				setAvoirs(avoirsResponse.avoirs);
				setCountAvoirs(avoirsResponse.countAvoirs);
			})
			.catch(() =>
				setError('Une erreur est survenue lors de la récuparation des avoirs.'),
			)
			.finally(() => {
				setLoading(false);
			});
	};

	function downloadSelected() {
		setDownloadLoading(true);

		type TResponse = {
			factures: Pick<Avoir, 'pdfPath' | 'numeroAvoir'>[];
		};
		request<TResponse>(avoirsByClientIdQuery, { clientId, selectedIDs })
			.then(({ factures }) => {
				setSelectedIDs([]);
				downloadManyFiles(
					factures.map((f) => ({ href: f.pdfPath, name: f.numeroAvoir })),
				);
			})
			.catch((err) => alert(err.message))
			.finally(() => setDownloadLoading(false));
	}

	useEffect(() => {
		const clauses = setClauses();
		fetchAvoirs(clauses).then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.key]);

	return (
		<TableWrapper>
			<TableHeader>
				<div className={styles.orderAndFilter}>
					<OrderBy fields={fields} />
					<FilterByDateRange label="Date" />
					<ExportSelectedButton
						loading={downloadLoading}
						number={selectedIDs.length}
						onClick={downloadSelected}
					/>
				</div>
			</TableHeader>
			<ListeAvoirs
				avoirs={avoirs}
				loading={loading}
				error={error}
				selectableRows={{ selectedIDs, setSelectedIDs }}
			/>
			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={countAvoirs}
				itemsCountPerPage={ITEMS_PER_PAGE}
			/>
		</TableWrapper>
	);
};

function mapStateToProps(state: AppState) {
	return { clientId: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(MesAvoirs));
