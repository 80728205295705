import React from 'react';
import { thinSpace } from '../../../../utils/french';
import styles from './LienSite.module.scss';

function LienSite() {
	return (
		<p className={styles.wrapper}>
			Vous avez une question, une demande
			<br /> d’information, un projet{thinSpace}:
			<a
				target="_blank"
				className={styles.link}
				rel="noopener noreferrer"
				href="https://emarketplace.fr"
			>
				<span className={styles.arrow} />
				<strong>Rendez-vous</strong> sur notre site
			</a>
		</p>
	);
}

export default LienSite;
