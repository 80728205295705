import React, { useEffect, useState } from 'react';
import styles from './CreateSelection.module.scss';
import Input from '../Input/Input';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../Common/Button/Button';
import ErrorMessage from '../../Common/Form/ErrorMessage/ErrorMessage';
import { AppState } from '../../../store';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import Select, { OptionsType } from 'react-select';
import sectionStyles from '../../Common/CompteUser/Formulaire/Formulaire.module.scss';
import inputStyles from '../../Common/Form/Input/Input.module.scss';
import Dropzone from '../../Common/Dropzone/Dropzone';
import { useTitle } from '../../../hooks/useTitle';
import { useToasts } from 'react-toast-notifications';

const getProduitsByFournisseurRequest = loader(
	'./getProduitsByFournisseur.graphql',
);
const createSelection = loader('./createSelection.graphql');

type CreateSelectionForm = {
	nom: string;
	produits: string[];
	image: string;
};

type OptionProduit = {
	value: string;
	label: string;
};

type Props = {
	fournisseurId: string;
};

type Produit = {
	id: string;
	nom: string;
};

type ResponseProduits = {
	produits: Produit[];
};

const CreateSelection = ({ fournisseurId }: Props) => {
	useTitle('Ajouter une sélection');
	const {
		register,
		errors,
		handleSubmit,
		clearErrors,
		control,
		setValue,
		getValues,
	} = useForm<CreateSelectionForm>();
	const { addToast } = useToasts();
	const [produitsOptions, setProduitOptions] = useState<OptionProduit[]>([]);
	const [imageSrc, setImageSrc] = useState('');
	const [loading, setLoading] = useState(false);
	const PRODUITS_FIELD = 'produits';

	const onSubmit = async (data: CreateSelectionForm) => {
		setLoading(true);
		const selection = {
			image: data.image[0],
			nom: data.nom,
			produits: data.produits,
		};
		await request(createSelection, selection).finally(() => {
			addToast('La sélection a été ajoutée.', {
				appearance: 'success',
				autoDismiss: true,
			});
			resetForm(); // use a manual reset because reset from react hook form doesn't work with Controller.
			setLoading(false);
		});
	};

	const protuitsToOptions = (produits: Produit[]): OptionProduit[] => {
		return produits.map(
			(produit: Produit): OptionProduit => ({
				value: produit.id,
				label: produit.nom,
			}),
		);
	};

	const resetForm = () => {
		setValue('nom', undefined);
		setImageSrc('');
		setValue('produits', []);
		setValue('image', undefined);
	};

	const fetchProduitsByFournisseur = async (fournisseurId: string) => {
		const { produits } = await request<ResponseProduits>(
			getProduitsByFournisseurRequest,
			{
				fournisseurId: fournisseurId,
			},
		);
		return produits;
	};

	const handleChangeDropzone = (url: string) => {
		setImageSrc(url);
	};

	const handleChangeProduit = (selectedOptions: OptionsType<OptionProduit>) => {
		if (selectedOptions) {
			const selectedIds = selectedOptions.map((option) => option.value);
			setValue(PRODUITS_FIELD, selectedIds);
			clearErrors(PRODUITS_FIELD);
		} else {
			setValue(PRODUITS_FIELD, []);
		}
	};

	useEffect(() => {
		fetchProduitsByFournisseur(fournisseurId).then((produits) => {
			setProduitOptions(protuitsToOptions(produits));
		});
	}, [fournisseurId]);

	return (
		<div className={styles.container}>
			<h1 className={styles.h1}>Ajouter une sélection</h1>

			<form onSubmit={handleSubmit(onSubmit)}>
				<label>Image *</label>
				<div className={styles.dropzone}>
					<Dropzone
						name="image"
						imageWidthToDisplay={600}
						imageSrc={imageSrc}
						className={[sectionStyles.column, inputStyles.label].join(' ')}
						onChange={({ file, tempURL }) => handleChangeDropzone(tempURL)}
						register={register({ required: true })}
					/>
					{errors.image && (
						<ErrorMessage>Veuillez mettre une image.</ErrorMessage>
					)}
				</div>

				<Input
					name="nom"
					label="Nom de la sélection *"
					type="text"
					register={register({ required: true })}
				/>
				{errors.nom && (
					<ErrorMessage>Veuillez renseigner un nom de sélection.</ErrorMessage>
				)}

				<label>Produits *:</label>
				<Controller
					control={control}
					name={PRODUITS_FIELD}
					defaultValue={[]}
					rules={{
						required: true,
						validate: (value) => value.length > 0,
					}}
					render={() => (
						<Select
							value={produitsOptions.filter((option) =>
								getValues(PRODUITS_FIELD).includes(option.value),
							)}
							onChange={handleChangeProduit}
							isMulti
							options={produitsOptions}
							placeholder="Sélectionner au moins un produit."
							className={styles.produits}
						/>
					)}
				/>
				{errors.produits && (
					<ErrorMessage>
						Veuillez sélectionner au moins un produit.
					</ErrorMessage>
				)}

				<div className={styles.buttonAdd}>
					<Button submit loading={loading}>
						Ajouter la sélection
					</Button>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = (state: AppState) => ({
	fournisseurId: state.compte.id,
});

export default withRouter(connect(mapStateToProps)(CreateSelection));
