import React from 'react';

import { useTitle } from '../../../hooks/useTitle';
import styles from './CreationProduit.module.scss';

import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import BtnBack from '../../Common/BtnBack/BtnBack';
import XlsxForm from './XlsxForm';
import FormulaireProduit from '../FormulaireProduit/FormulaireProduit';

function CreationProduit() {
	useTitle('Nouveau Produit');

	return (
		<TableWrapper className={styles.wrapper}>
			<TableHeader className={styles.wrapperHeader}>
				<BtnBack to="/produits/liste" className={styles.btnBack} withText />
				<p className={styles.title}>Création d'un Produit</p>
			</TableHeader>
			<div className={styles.formContainer}>
				<FormulaireProduit type="create" />
				<XlsxForm />
			</div>
		</TableWrapper>
	);
}

export default CreationProduit;
