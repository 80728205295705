import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import request from '../../../utils/request';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './Alertes.module.scss';
import Table from '../../Common/Table/Table';
import { french } from '../../../utils/french';
import BtnPoubelle from '../../Common/BtnPoubelle/BtnPoubelle';
import { RowProps } from '../../Common/Table/types';
import { hideLoginError, logIn } from '../../../store/compte/actions';
import { connect } from 'react-redux';
import { AppState } from '../../../store';

const queryActu = loader('./queries/getAlertes.graphql');

function Alertes() {
	useTitle('Alertes');

	const [clients, setClients] = useState<[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		let isMounted = true;
		setLoading(true);
		setError('');

		type TResponse = { alertesAdmin: [] };
		request<TResponse>(queryActu)
			.then((res) => {
				isMounted && setClients(res.alertesAdmin);
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, []);

	function deleteActu(id: string) {
		setError('');

		// request(mutationDeleteActu, { id })
		// 	.then(() => setActualites(actualites.filter(a => a.id !== id)))
		// 	.catch(err => setError(err.message));
	}

	// async function connect(id: string) {
	// 	setError('');
	// 	// window.location.reload()
	// }

	// function invertActus(index1: number, index2: number) {
	// 	// On récupère les actus
	// 	// const actu1: Actualite | null = actualites[index1];
	// 	// const actu2: Actualite | null = actualites[index2];

	// 	// Annule si une des deux n'existe pas (donc qu'on est à une extrémité de la liste)
	// 	// if (!actu1 || !actu2) return;

	// 	// On récupère les ids et ordres de chaque actu (nécessaire pour casser la référence objet)
	// 	// const { id: id1, ordre: ordre1 } = actu1;
	// 	// const { id: id2, ordre: ordre2 } = actu2;

	// 	setError('');

	// 	// request(mutationChangeOrderActu, { id1, id2, ordre1, ordre2 })
	// 	// 	.then(() => {
	// 	// 		const actus = actualites.map(a => {
	// 	// 			if (a.id === actu1.id) a.ordre = ordre2;
	// 	// 			else if (a.id === actu2.id) a.ordre = ordre1;
	// 	// 			return a;
	// 	// 		});
	// 	// 		setActualites(actus.sort((a, b) => a.ordre - b.ordre));
	// 	// 	})
	// 	// 	.catch(err => setError(err.message));
	// }

	return (
		<>
			<div className={styles.container}>
				<h1 className={styles.title}>Alertes</h1>
			</div>
			<TableWrapper>
				<Table
					error={error}
					loading={loading}
					noResultMessage={'Aucune Alertes'}
					columns={[
						{ content: 'id', center: true },
						{ content: 'Id client', center: true },
						{ content: 'Id fournisseur', center: true },
						{ content: 'Url', center: true },
						{ content: 'Texte', center: true },
						{ content: 'Supprimer', center: true, isButton: true },
					]}
					rows={clients.map(({ id, client, fournisseur, url, texte }, i) => {
						// const sr: String = texte;
						return {
							id,
							mobileTitle: (
								<div className={styles.mobileTitle}>Alertes - eMarketplace</div>
							),
							href: '/admin/clients/edition/' + id,
							cells: [
								{ content: french(id), className: styles.actuTitle },
								{
									content: french(client ? (client as any).id : ''),
									className: styles.actuTitle,
								},
								{
									content: french(fournisseur ? (fournisseur as any).id : ''),
									className: styles.actuTitle,
								},
								{
									content: url ? french(url) : '',
									className: styles.actuTitle,
								},
								{ content: french(texte), className: styles.actuTitle },
								{
									stopPropagation: true,
									content: (
										<BtnPoubelle
											title="Supprimer l'actualité"
											onClick={() => deleteActu(id)}
										/>
									),
								},
							],
						} as RowProps;
					})}
				/>

				{/*<div className={styles.buttonWrapper}>*/}
				{/*	<Link to={{ pathname: '/actualites/creation' }}>*/}
				{/*		<Button>Ajouter une actualité</Button>*/}
				{/*	</Link>*/}
				{/*</div>*/}
			</TableWrapper>
		</>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isLoggingIn: state.compte.isLoggingIn,
		isLoggedIn: state.compte.isLoggedIn,
		loginError: state.compte.error,
	};
}

// export default Clients;
export default withRouter(
	connect(mapStateToProps, { logIn, hideLoginError })(Alertes),
);
