import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useUnmount } from '../../../hooks/useUnmount';
import { AppState } from '../../../store';
import { User } from '../../../store/compte/types';
import {
	Client,
	Commande,
	CommandeLigne,
	Contact,
	Label,
	Produit,
} from '../../../types';
import { StatutCommandeLigne } from '../../../types/StatutCommandeLigne';
import request from '../../../utils/request';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import styles from './DetailCommande.module.scss';
import Header from './Header/Header';
import ListeProduits from './ListeProduits/ListeProduits';
import PopinUpdateStatus from './PopinUpdateStatus/PopinUpdateStatus';
import DropzonePDF from '../../Common/DropzonePDF/DropzonePDF';

const getCommandeQuery = loader('./getCommande.graphql');
const linkFactureToFournisseur = loader('./createFacture.graphql');
const linkAvoirToFournisseur = loader('./createAvoir.graphql');
const getFactureQuery = loader('./getFacture.graphql');
const getAvoirQuery = loader('./getAvoir.graphql');

export interface QueryResponse {
	commande: Pick<Commande, 'id' | 'dateLivraison'> & {
		client: Pick<Client, 'id' | 'enseigne' | 'raisonSociale'> & {
			contacts: Pick<
				Contact,
				'id' | 'nom' | 'prenom' | 'email' | 'telephones'
			>[];
		};
		lignes: (Pick<
			CommandeLigne,
			'id' | 'quantite' | 'statut' | 'commentaire'
		> & {
			produit: Pick<Produit, 'id' | 'nom' | 'imageSrc' | 'unite'> & {
				labels: Pick<Label, 'abreviation'>[];
				code: string;
			};
		})[];
	};
}

interface Props extends RouteComponentProps<{ id: string }> {
	idFournisseur: User['id'];
}

type CommandeResponse = {
	commande: {
		facture: {
			id: string;
			pdfName: string;
			pdfPath: string;
		};
		avoir: {
			id: string;
			pdfName: string;
			pdfPath: string;
		};
	};
};

export function _DetailCommande({
	idFournisseur,
	match: {
		params: { id },
	},
}: Props) {
	const { isMounted } = useUnmount();
	const [commande, setCommande] = useState<QueryResponse['commande']>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [popinStatusOpened, setPopinStatusOpened] = useState(false);
	const [facturePdfName, setFacturePdfName] = useState<string | undefined>(
		undefined,
	);
	const [facturePdfPath, setFacturePdfPath] = useState<string | null>(null);
	const [avoirPdfName, setAvoirPdfName] = useState<string | undefined>(
		undefined,
	);
	const [avoirPdfPath, setAvoirPdfPath] = useState<string | null>(null);
	const statut = commande?.lignes[0].statut;

	useEffect(() => {
		setError('');
		setLoading(true);
		request<QueryResponse>(getCommandeQuery, { idCommande: id, idFournisseur })
			.then((res) => isMounted.current && setCommande(res.commande))
			.catch((err) => setError(err.message))
			.finally(() => {
				setLoading(false);
			});
	}, [id, idFournisseur, isMounted]);

	const fetchFacture = async (commandeId: string) => {
		const variables = {
			commandeId: commandeId,
		};

		const response = await request<CommandeResponse>(
			getFactureQuery,
			variables,
		);

		if (response.commande.facture) {
			setFacturePdfName(response.commande.facture.pdfName);
			setFacturePdfPath(response.commande.facture.pdfPath);
		}
	};

	const fetchAvoir = async (commandeId: string) => {
		const variables = {
			commandeId: commandeId,
		};

		const response = await request<CommandeResponse>(getAvoirQuery, variables);

		if (response.commande.avoir) {
			setAvoirPdfName(response.commande.avoir.pdfName);
			setAvoirPdfPath(response.commande.avoir.pdfPath);
		}
	};

	useEffect(() => {
		if (commande) {
			fetchFacture(commande.id).then();
			fetchAvoir(commande.id).then();
		}
	}, [commande]);

	function setCommandeStatus(statut: StatutCommandeLigne) {
		if (commande && isMounted) {
			setCommande({
				...commande,
				lignes: commande.lignes.map((l) => ({ ...l, statut })),
			});
		}
	}

	const linkFactureToTheFournisseur = async (file: File | null) => {
		setFacturePdfName(file?.name);
		const variables = {
			commandeId: commande?.id,
			clientId: commande?.client.id,
			pdf: file,
		};
		await request(linkFactureToFournisseur, variables);
	};

	const linkAvoirToTheFournisseur = async (file: File | null) => {
		setAvoirPdfName(file?.name);
		const variables = {
			commandeId: commande?.id,
			clientId: commande?.client.id,
			pdf: file,
		};
		await request(linkAvoirToFournisseur, variables);
	};

	return (
		<TableWrapper>
			<Header
				statut={statut}
				client={commande?.client}
				openPopin={() => setPopinStatusOpened(true)}
				dateLivraison={commande?.dateLivraison}
			/>
			<ListeProduits
				error={error}
				loading={loading}
				lignes={commande?.lignes || []}
			/>

			<div className={styles.containFactureAvoir}>
				<div>
					Facture : {facturePdfName}
					<DropzonePDF
						defaultPdfPath={facturePdfPath}
						onFileSelect={(file) => linkFactureToTheFournisseur(file)}
					/>
				</div>
				<div>
					Avoir : {avoirPdfName}
					<DropzonePDF
						defaultPdfPath={avoirPdfPath}
						onFileSelect={(file) => linkAvoirToTheFournisseur(file)}
					/>
				</div>
			</div>
			<PopinUpdateStatus
				statut={statut}
				isOpen={popinStatusOpened}
				setCommandeStatus={setCommandeStatus}
				close={() => setPopinStatusOpened(false)}
				lignesIds={commande?.lignes.map((l) => l.id) || []}
			/>
			<div className={styles.emptyFooter} />
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { idFournisseur: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(_DetailCommande));
