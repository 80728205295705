import React, { useEffect, useState } from 'react';
import Media from 'react-responsive';
import { media } from '../../../utils/breakpoints/breakpoints';
import Alertes from '../../Common/Alertes/Alertes';
import BtnAlertes from '../../Common/BtnAlertes/BtnAlertes';
import Logo from '../../Common/Logo/Logo';
import MenuGeneral from '../../Common/MenuGeneral/MenuGeneral';
import BtnPanier from '../BtnPanier/BtnPanier';
import OmniSearch from '../OmniSearch/OmniSearch';
import styles from './Header.module.scss';
import Loupe from './Loupe/Loupe';
import PopinOmniSearch from './PopinOmniSearch/PopinOmniSearch';
import RecapRechercheOrLogo from './RecapRechercheOrLogo/RecapRechercheOrLogo';
import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import { saveStateToLocalStorage } from '../../../utils/localStorage';
import Loader from '../../Common/Loader/Loader';
import { UserResponse } from '../../../store/compte/types';

const links = [
	{ title: 'Accueil', to: '/' },
	{ title: 'Mes commandes', to: '/commandes' },
	{ title: 'Mes commandes favorites', to: '/commandes-favorites' },
	{ title: 'Mes produits favoris', to: '/produits-favoris' },
	{ title: 'Mes factures', to: '/mes-factures' },
	{ title: 'Mes avoirs', to: '/mes-avoirs' },
	{ title: 'Mon compte', to: '/compte' },
	{ title: 'Contact', to: '/contact' },
	{ title: 'Mentions légales', to: '/mentions-legales' },
];

const oldAdminQuery = loader('./queries/oldAdmin.graphql');

const loginWithId = loader('./queries/loginWithId.graphql');

function Header() {
	const [oldAdmin, setOldAdmin] = useState({});
	const [omniSearchMobileOpen, setOmniSearchMobileOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	async function goToAdmin() {
		const { id } = oldAdmin as any;

		try {
			await request<UserResponse>(loginWithId, {
				id,
				model: 'Admin',
			});
		} catch (error) {
			console.log(error);
		}

		saveStateToLocalStorage('panier', {
			favorite: false,
			lignes: [],
			comments: [],
		});
		localStorage.removeItem('cmdFav');

		window.location.href = '/admin';
	}

	useEffect(() => {
		/* eslint-disable @typescript-eslint/no-unused-vars */
		let isMounted = true;
		setLoading(true);

		type TResponse = { oldAdmin: {} };
		request<TResponse>(oldAdminQuery)
			.then((res) => {
				if (res.oldAdmin) {
					setOldAdmin(res.oldAdmin);
				}
			})
			.catch((err) => console.error(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<header className={styles.wrapper}>
			{loading ? (
				<Loader />
			) : (
				<>
					<Media query={media['<laptop']}>
						<RecapRechercheOrLogo
							onClick={() => setOmniSearchMobileOpen(true)}
						/>

						<Loupe
							className={styles.loupeMobile}
							onClick={() => setOmniSearchMobileOpen(true)}
						/>

						<PopinOmniSearch
							isOpen={omniSearchMobileOpen}
							close={() => setOmniSearchMobileOpen(false)}
						/>
					</Media>

					<Media query={media['>=laptop']}>
						<Logo />

						<OmniSearch className={styles.omniSearch} />
						{Object.keys(oldAdmin).length ? (
							<button className="btnClasse" onClick={() => goToAdmin()}>
								<p className={styles.nom}>Retourner vers l'administration</p>
							</button>
						) : (
							<></>
						)}

						<BtnAlertes />
						<BtnPanier />
					</Media>

					<MenuGeneral links={links} />
					<Alertes />
				</>
			)}
		</header>
	);
}

export default Header;
