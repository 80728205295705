import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useTitle } from '../../../hooks/useTitle';
import { useUnmount } from '../../../hooks/useUnmount';
import request from '../../../utils/request';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import BtnBack from '../../Common/BtnBack/BtnBack';
import styles from './EditionCategorie.module.scss';
import Loader from '../../Common/Loader/Loader';
import FormulaireCategorie from '../FormulaireCategorie/FormulaireCategorie';

const getCategorieQuery = loader('./queries/getCategorie.graphql');

interface Props {
	id: string;
}

function EditionCategorie({ id }: Props) {
	useTitle(`Édition d'une Categorie`);

	const { isMounted } = useUnmount();

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState<any>([]);
	useEffect(() => {
		setError('');
		setLoading(true);
		/* eslint-disable @typescript-eslint/no-unused-vars */
		let isMounted = true;
		type TResponse = { clients: [] };
		request<TResponse>(getCategorieQuery, { id })
			.then((res: any) => {
				if (res) {
					setData(res.categorie);
				}
			})
			.catch((err) => {
				setError(err.message);
			})
			.finally(() => setLoading(false));
	}, [id, isMounted]);

	return (
		<TableWrapper className={styles.wrapper}>
			<TableHeader className={styles.wrapperHeader}>
				<BtnBack to="/categories" className={styles.btnBack} withText />
				<p className={styles.title}>Édition d'une catégorie</p>
			</TableHeader>
			{error ? (
				<p>{error}</p>
			) : loading ? (
				<Loader />
			) : (
				<FormulaireCategorie data={data} />
			)}
		</TableWrapper>
	);
}

export default EditionCategorie;
