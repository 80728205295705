import React, { useEffect, useState } from 'react';
import TableHeader from '../../Common/Table/TableHeader/TableHeader';
import OrderBy from '../../Common/OrderBy/OrderBy';
import TableWrapper from '../../Common/Table/TableWrapper/TableWrapper';
import FilterByDateRange from '../../Common/FilterByDateRange/FilterByDateRange';
import ListeFactures from './ListeFactures/ListeFactures';
import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TablePagination from '../../Common/Table/TablePagination/TablePagination';
import { User } from '../../../store/compte/types';
import { AppState } from '../../../store';
import { connect } from 'react-redux';
import { downloadManyFiles } from '../../../utils/download';
import ExportSelectedButton from '../../Common/ExportSelectedButton/ExportSelectedButton';
import styles from './MesFactures.module.scss';

const facturesQuery = loader('./getFactures.graphql');
const facturesByClientIdQuery = loader('./getFacturesByClientIdQuery.graphql');

export type Facture = {
	id: string;
	dateFacturation: string;
	numeroFacture: string;
	pdfPath: string;
	pdfName: string;
};

interface FacturesResponse {
	factures: Facture[];
	countFactures: number;
}

type Clauses = {
	where: {};
	skip: number;
	first: number;
	orderBy: string;
};

export interface Props extends RouteComponentProps {
	clientId: User['id'];
}

const MesFactures = ({ location, clientId }: Props) => {
	const ITEMS_PER_PAGE = 10;
	const [factures, setFactures] = useState<Facture[]>([]);
	const [countFactures, setCountFactures] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [fields] = useState([
		{ name: 'Date de facturation', slug: 'dateFacturation' },
		{ name: 'Numéro de facture', slug: 'numeroFacture' },
	]);

	const makeClauses = (): Clauses => {
		const searchParams = new URLSearchParams(location.search);
		const orderBy = searchParams.get('orderBy') || fields[0].slug;
		const order = searchParams.get('order') || 'ASC';
		const dateStart = searchParams.get('dateStart');
		const dateEnd = searchParams.get('dateEnd');
		const pageIndex = Number(searchParams.get('page') || 1);

		return {
			where: {
				client: { id: clientId },
				...(dateStart && { dateFacturation_gte: dateStart }),
				...(dateEnd && { dateFacturation_lte: dateEnd + 'T23:59:59.999Z' }),
			},
			skip: ITEMS_PER_PAGE * (pageIndex - 1),
			first: ITEMS_PER_PAGE,
			orderBy: `${orderBy}_${order}`,
		};
	};

	const fetchFactures = async (clauses: Clauses) => {
		request<FacturesResponse>(facturesQuery, clauses)
			.then((facturesResponse) => {
				setFactures(facturesResponse.factures);
				setCountFactures(facturesResponse.countFactures);
			})
			.catch(() =>
				setError(
					'Une erreur est survenue lors de la récuparation des factures.',
				),
			)
			.finally(() => {
				setLoading(false);
			});
	};

	function downloadSelected() {
		setDownloadLoading(true);

		type TResponse = {
			factures: Pick<Facture, 'pdfPath' | 'numeroFacture'>[];
		};
		request<TResponse>(facturesByClientIdQuery, { clientId, selectedIDs })
			.then(({ factures }) => {
				setSelectedIDs([]);
				downloadManyFiles(
					factures.map((f) => ({ href: f.pdfPath, name: f.numeroFacture })),
				);
			})
			.catch((err) => alert(err.message))
			.finally(() => setDownloadLoading(false));
	}

	useEffect(() => {
		const clauses = makeClauses();
		fetchFactures(clauses).then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.key]);

	return (
		<TableWrapper>
			<TableHeader>
				<div className={styles.orderAndFilter}>
					<OrderBy fields={fields} />
					<FilterByDateRange label="Date" />
					<ExportSelectedButton
						loading={downloadLoading}
						number={selectedIDs.length}
						onClick={downloadSelected}
					/>
				</div>
			</TableHeader>
			<ListeFactures
				factures={factures}
				loading={loading}
				error={error}
				selectableRows={{ selectedIDs, setSelectedIDs }}
			/>
			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={countFactures}
				itemsCountPerPage={ITEMS_PER_PAGE}
			/>
		</TableWrapper>
	);
};

function mapStateToProps(state: AppState) {
	return { clientId: state.compte.id };
}

export default withRouter(connect(mapStateToProps)(MesFactures));
