import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useTitle } from '../../../hooks/useTitle';
import { AppState } from '../../../store';
import { getActualites } from '../../../store/actualites/actions';
import { ActualitesState } from '../../../store/actualites/types';
import { media } from '../../../utils/breakpoints/breakpoints';
import { french } from '../../../utils/french';
import styles from './Actualites.module.scss';
import ActualiteSingle from './ActualiteSingle/ActualiteSingle';

export interface Props extends ActualitesState {
	getActualites: typeof getActualites;
	idClient: string;
}

export function _Actualites({
	error,
	loading,
	list,
	getActualites,
	idClient,
}: Props) {
	useTitle('Actualités');

	useEffect(() => {
		getActualites(idClient);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getActualites]);

	if (error) return <p className={styles.error}>{error}</p>;

	return (
		<div className={styles.wrapper}>
			<MediaQuery query={media['<laptop']}>
				<h1 className={styles.title}>{french(`À ne pas manquer !`)}</h1>
			</MediaQuery>

			{loading
				? [...new Array(3)].map((_, i) => (
						<ActualiteSingle
							key={i}
							id=""
							titre=""
							contenu=""
							imageSrc=""
							className={styles.single}
						/>
				  ))
				: list.map((actu) => (
						<ActualiteSingle
							key={actu.id}
							{...actu}
							className={styles.single}
						/>
				  ))}

			{/* Fake pour le flex */}
			{/* <div className={styles.single} /> */}
		</div>
	);
}

function mapStateToProps(state: AppState) {
	const idClient = state.compte.id;

	return {
		...state.actualites,
		idClient: idClient,
	};
}

export default connect(mapStateToProps, { getActualites })(_Actualites);
