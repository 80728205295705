import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	CategoriesThunkResult,
	GET_CATEGORIES_FAIL,
	GET_CATEGORIES_START,
	GET_CATEGORIES_SUCCESS,
} from './types';
import { Client } from '../../types';

const query = loader('./getCategories.graphql');
const queryClient = loader('./getClient.graphql');

type TResponse = {
	categories: {
		nom: string;
		slug: string;
		pictoSrc: string;
		sousCategories: {
			nom: string;
			slug: string;
		}[];
	}[];
};

export function getCategories(id: string): CategoriesThunkResult {
	return async function (dispatch) {
		try {
			dispatch({ type: GET_CATEGORIES_START });
			const client = await getClient(id);
			const variables = { id: client.fournisseur.id };
			const tResponse = await request<TResponse>(query, variables);

			const categories = tResponse.categories;
			dispatch({ type: GET_CATEGORIES_SUCCESS, list: categories });
		} catch (err) {
			dispatch({ type: GET_CATEGORIES_FAIL, error: err.message });
		}
	};
}

export const getClient = async (id: string) => {
	const variables = { id };
	const { client } = await request<{ client: Client }>(queryClient, variables);
	return client;
};
