import React from 'react';
import { convertUnite, french, thinSpace } from '../../../../utils/french';
import { photosProduitsSizes } from '../../../../utils/imgSizes';
import styles from '../../../Client/DetailCommande/ListeProduits/ListeProduits.module.scss';
import Label from '../../../Common/Label/Label';
import Table from '../../../Common/Table/Table';
import { RowProps } from '../../../Common/Table/types';
import { QueryResponse } from '../DetailCommande';
import ImgTable from '../../../Common/ImgTable/ImgTable';

interface Props {
	error?: string;
	loading?: boolean;
	lignes: QueryResponse['commande']['lignes'];
}

function ListeProduits({ error, loading, lignes }: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage="Cette commande n'existe pas"
			columns={[
				{ content: 'Photo', center: true, mobileHidden: true },
				{ content: 'Nom', mobileHidden: true },
				{ content: 'Code' },
				{ content: 'Labels' },
				{ content: 'Quantité' },
				{ content: 'Commentaire' },
			]}
			rows={lignes.map((l) => {
				const image = (
					<ImgTable
						fallback="jpg"
						alt={l.produit.nom}
						src={l.produit.imageSrc}
						className={styles.photo}
						mediaQueries={[{ width: 80 }]}
						otherSizes={photosProduitsSizes}
					/>
				);

				return {
					id: l.id,

					mobileTitle: (
						<div className={styles.mobileTitle}>
							{image}
							{l.produit.nom}
						</div>
					),

					cells: [
						{ content: image },
						{ content: l.produit.nom, className: styles.nom },
						{ content: l.produit.code },
						{
							content: l.produit.labels.length
								? l.produit.labels.map((lab, i) => (
										<Label key={i}>{lab.abreviation}</Label>
								  ))
								: undefined,
						},
						{
							content: (
								<>
									<strong className={styles.big}>
										{french(
											l.quantite.toFixed(l.produit.unite === 'KG' ? 2 : 0),
										)}
									</strong>
									{thinSpace}
									{convertUnite(l.produit.unite, l.quantite)}
								</>
							),
						},
						{ content: l.commentaire },
					],
				} as RowProps;
			})}
		></Table>
	);
}

export default ListeProduits;
